<template>
  <div class="mianbaoxie">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="item in lists" :key="item.path">{{ item.meta.title }}</el-breadcrumb-item>

    </el-breadcrumb>

    <div class="touxiangyonghuming">
      <img src="https://api.dfwlkeji.com/266/266_log.jpg" alt="" class="avatar">
      <span class="username"></span>

      <div style="position: absolute; top: 40px; right: -70px;">
        <el-dropdown :hide-on-click="false">
          <span class="el-dropdown-link">
            {{ yonghu.nickname }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>用户信息</el-dropdown-item>
            <el-dropdown-item>退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      lists: [],
      yonghu: ""


    }
  },


  created() {

    console.log("开始验证");


    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .get("https://api.dfwlkeji.com/my/userinfo", { headers: headers })

      .then((response) => {

        // 请求成功处理逻辑
        console.log('面包');

        console.log(response.data.message);

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("面包2");

          this.$router.push({ path: "../Loin" });



        }else{

        this.yonghu = response.data.data;



        }






      })
      .catch((error) => {
        // 请求失败处理逻辑

        //  跳转路由  返回登录页面



        console.error(error);
      });






  },









  methods: {

  },
  watch: {
    $route(to, from) {
      this.lists = to.matched;
    }
  },
  mounted() {
    this.lists = this.$route.matched;
  }
}
</script>

<style>
.mianbaoxie {
  transform: translate(-5%, 25%);
}

.avatar {
  width: 40px;
  height: 40px;
  transform: translate(4030%, -40%);
}

.dropdown-container {
  top: 20px;
  right: 700px;
}

.dropdown-link {
  margin-left: 11px;
  transform: translate(80%, -50%);
}

.yonghuming {
  transform: translate(95%, -50%);
}

.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  right: 50px;
}

.el-icon-arrow-down {
  font-size: 12px;
  right: 50px;
}
</style>