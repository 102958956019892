<template>
  <div>



    <div class="order-list">

      <!-- 顶部按钮 -->
      <div style="background: #ffff; width: 66.5%; height: 110px; position:fixed; top: 40px;">

        <div class="order-actions">





          <el-badge :value="quanbu_shuliang" style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="success" style="margin-left: 0px; " plain @click="quanbudingdan"> 全部订单 </el-button>

          </el-badge>

          <el-badge :value="daidiaodud_shuliang" style=" margin-top: 10px; margin-right: 15px;">


            <el-button type="warning" style="margin-left: 0px;" @click="dianji_daipeisong">待调度</el-button>
          </el-badge>

          <el-badge style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="primary" @click="dianji_peisongzhong">配送中</el-button>

          </el-badge>

          <el-badge style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="success" @click="dianji_yiwancheng">已完成</el-button>

          </el-badge>



          <el-badge style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="success" plain @click="weizhifu">未支付</el-button>
          </el-badge>

          <el-badge style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="success" plain>已支付</el-button>
          </el-badge>

          <el-badge :value="yichaoshi_shuliang" style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="danger" plain @click="yichaoshi">已超时</el-button>

          </el-badge>

          <el-badge :value="5" style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="danger" plain>催单中</el-button>

          </el-badge>

          <el-badge :value="yiquxiao_shuliang" style=" margin-top: 10px; margin-right: 15px;">

            <el-button type="info" plain @click="xianshi_yiquxiao">已取消</el-button>


          </el-badge>

          <el-badge style=" margin-top: 15px; margin-right: 15px; margin-left: 20px;">

            30秒自动刷新 <el-switch v-model="zhidong_shuaxin">
            </el-switch>




          </el-badge>


        </div>

        <div class="order-actions">

          <!-- <el-button type="warning" style="margin-left: 0px;" @click="dianji_daipeisong">待调度</el-button>
          <el-button type="primary" @click="dianji_peisongzhong">配送中</el-button>
          <el-button type="success" @click="dianji_yiwancheng">已完成</el-button> -->

        </div>

      </div>



      <!-- //订单列表 -->

      <div v-for="order in orderList" :key="order.orderNo" class="order-item"
        :style="{ borderColor: order.DD_zhuangtai === 5 ? 'red' : order.DD_zhuangtai === 0 ? '#606266' : order.DD_zhuangtai === 6 ? '#67C23A' : '#409EFF' }">

        <div class="order-header">
          <div class="order-header-inf" style="font-size: 15px; padding-left: 5px;">
            <div>

              <strong style="color: rgb(136, 133, 133); font-size: 20px; ">#{{ order.SH_shanghudanhao }}</strong>
              <strong style="color: rgb(3, 197, 77); font-size: 16px; "> {{ order.SH_name }} </strong>

              <strong> {{ order.DD_xiadanshijian }}</strong>

              <strong style="color: rgb(76, 80, 76);  font-size: 12px;"> 订单编号:{{ order.DD_dingdanbianhao }} </strong>
              <!-- :style="{ background: order.DD_zhuangtai === 6 ? '#67C23A' : '#000000' }" -->

              <div style="float:right; margin-left: 20px; width: 70px; height: 30px; border-radius: 8px;  ">

                <strong :style="{
                  color: order.DD_zhuangtai > 0 && order.DD_zhuangtai < 5 ? 'rgb(0, 0, 255)' :
                    order.DD_zhuangtai === 5 ? '#F56C6C' : order.DD_zhuangtai === 6 ? '#67C23A' : 'rgb(255, 0, 0)', marginLeft: '9px', fontSize: '18px'
                }">



                  {{ order.DD_zhuangtai === 0 ?
                    '未支付' : order.DD_zhuangtai === 1 ? '待调度' : order.DD_zhuangtai === 2 ? '配送中' :
                      order.DD_zhuangtai === 3 ?
                        '待取货' : order.DD_zhuangtai === 4 ? '已取货' : order.DD_zhuangtai === 5 ? '已超时' : order.DD_zhuangtai === 6 ?
                          '已完成' : order.DD_zhuangtai === 7 ?
                            '未接单' : order.DD_zhuangtai === 8 ? '已取消' : order.DD_zhuangtai }}


                </strong>
                




                <strong  v-if="order.YH_cuidan==1" style="color: red; font-size: 20px;">

                  有催单



                            
                </strong>






              </div>


            </div>
            <div></div>
          </div>

        </div>


        <div class="order-info" style="margin-top: 0px;">

          <div style="border-bottom: 1px solid #e1e2e4 ; margin-top: 0px;">
            下单人： <strong style="color: rgb(76, 80, 76);  font-size: 16px;"> {{ order.YH_xiaddanrenxinxi }} </strong>
            <strong style="color: rgb(76, 80, 76);  font-size: 14px; margin-left: 15px;">第18次下单</strong>


            <strong style="color: rgb(76, 80, 76);  font-size: 14px; margin-left: 15px;">地址： {{ order.YH_dizhi }}</strong>



          </div>

          <div style="border-bottom: 1px solid #8e9094 ; color: rgb(13, 14, 13);  margin-top: 8px; margin-bottom: 10px;">
            <div style=" margin-bottom: 5px;">
              <strong style="font-size: 15px;">配送员:</strong>

              <strong style="color: rgb(6, 100, 163);  font-size: 16px;  margin-left: 10px;"> {{ order.QS_name }}</strong>
              <strong style="color: rgb(76, 60, 223);  font-size: 12px;"> {{ order.QS_shouji }}</strong>
              <strong style="color: rgb(76, 80, 76);  font-size: 12px; margin-left: 15px;"> {{ order.QS_jiedanshijian
              }}</strong>

            </div>

          </div>



        </div>

        <div class="order-info">

          <div style=" margin-top: 5px; color: rgb(122, 120, 120);  font-size: 12px;">
            <strong>顾客实付金额:{{ order.YH_shifu }}元，</strong>
            <strong>含商品费:{{ order.DD_zongjia }}元，</strong>

            <strong>配速费:{{ order.YH_peisongfei }}元，</strong>
            <strong>附加费:{{ order.DD_fujiafei }}元，</strong>
            <strong>恶劣天气服务费:{{ order.DD_tianqi }}元，</strong>
            <strong>节日服务费:{{ order.DD_jieri }}元，</strong>

            <strong>平台服务费:{{ order.DD_fuwufei }}元；</strong>
            <strong style="color: rgb(2, 161, 69);">（本单平台收益:{{ order.DD_pingtaishouyi }}元）</strong>


          </div>


        </div>

        <div class="order-info">



          <div style=" margin-top: 5px;"><strong>商品信息:</strong> {{ order.SH_shangpin_id }}</div>
        </div>

        <div class="order-info" v-for=" shux in order.shangpin" :key="shux.orderNo">

          <div style=" font-size: 12px; margin-top: 5px; color: rgb(76, 80, 76)"><strong>{{ shux.name }}x{{ shux.shuliang
          }}</strong> </div>



        </div>


        <div class="order-info">



          <div style=" margin-top: 5px; color: rgb(137, 3, 179) "><strong>备注：</strong> {{ order.DD_beizhu }}</div>
        </div>


        <div style="margin-top: 20px;">


          <el-button type="success" @click="dianji_wancheng(order.DD_id, order.DD_zhuangtai)"
            v-if="order.DD_zhuangtai === 6 || order.DD_zhuangtai === 0 || order.DD_zhuangtai === 8 ? false : true">完成订单</el-button>

          <el-button type="info">订单详情</el-button>

          <el-button type="danger"
            @click="zhixing_quxiaodingdan(order.DD_id, order.DD_zhuangtai, order.DD_dingdanbianhao, order.YH_shifu)"
            v-if="order.DD_zhuangtai === 6 || order.DD_zhuangtai === 0 || order.DD_zhuangtai === 8 ? false : true">取消订单</el-button>


          <el-button type="primary" @click="zhixing_diaodu(order.DD_id, order.DD_zhuangtai)"
            v-if="order.DD_zhuangtai === 6 || order.DD_zhuangtai === 0 || order.DD_zhuangtai === 8 ? false : true">{{

              order.DD_zhuangtai === 1 ? '调度' : order.DD_zhuangtai === 7 ? '接单' : '重新调度' }}</el-button>


        </div>



      </div>



      <!-- 调度弹窗 -->

      <el-dialog title="调度窗口" @click="diaodu_TC = false" :visible.sync="diaodu_TC">

        <div style="display: flex;
    justify-content: left;
    align-items: center;
    height: 50vh; /* 设置容器高度为视口高度，使表单垂直居中 */">



          <!-- <el-select v-model="HUOQU_QISHOUID" placeholder="请选择骑手">
            <el-option v-for="item in zaixiand_SHUJU" :key="item.value" :label="item.label" :value="item.QS_name">
            </el-option>
          </el-select> -->



          <div style="width: 50%;  background: #b15757;">

            <div style="width: 100%;  height: 500px; background: rgb(223, 209, 214)">

              更新地图使用

            </div>


          </div>






          <div style="width: 50%; ">


            <template style="width: 50%; background: #000;">

              <el-table :data="zaixiand_SHUJU" style="width: 100%" height="500">


                <el-table-column fixed prop="QS_id" label="编号" width="80">
                </el-table-column>
                <el-table-column prop="QS_name" label="姓名" width="100">
                </el-table-column>
                <el-table-column prop="QS_nianling" label="配送中" width="150">
                </el-table-column>


                <el-table-column prop="zip" label="操作" width="100">

                  <template slot-scope="scope">
                    <el-button type="success" round @click="ZX_DIAODU(scope.row.QS_id)">调度</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </template>

          </div>








          <!-- <el-button type="primary" @click="ZX_DIAODU">主要按钮</el-button> -->


        </div>
      </el-dialog>


    </div>


    <div style="left: 85%; top:20%; position: fixed; width: 10%; height: 500px;  text-align: center;">

      <h2>今日完成订单</h2>
      <h2>{{ shuju_xianshi_wancheng }}</h2>
      <hr>

      <h2>今日交易金额</h2>
      <h2>{{ xianshi_shifu }}</h2>
      <hr>

      <h2>今日平台收益</h2>
      <h2>8</h2>
      <hr>

    </div>



  </div>
</template>

<script>

import axios from "axios";


import { qq_jied, quxiao_dindan } from "./dd"

export default {
  data() {
    return {

      orderList: [], //去除未支付的还有已完成的
      yiquxiao: '',//显示已取消的订单


      daidiaodud_shuliang: "",
      quanbu_shuliang: "", // 全部订单数量 
      yiquxiao_shuliang: "",// 全部已取消订单数量
      yichaoshi_shuliang: "",  //  已超时数量
      shuju_xianshi_wancheng: "", //显示数据 已完成的订单
      xianshi_shifu: "", //累计实付金额


      suoyoudingdan: [], //包含所有状态

      diaodu_TC: false,//调度弹窗

      //调度的订单id

      diaodu_ddID: '', //订单id

      zaixiand_SHUJU: [], //骑手列表

      zhidong_shuaxin: true// 自动刷新按钮


    };
  },




  // mounted() {
  //   // 每30秒刷新一次页面
  //   setInterval(() => {
  //     this.refreshData();
  //   }, 30000);


  // },



  created() {



    // 在组件创建时执行
    // console.log("组件创建");

    const id = this.$route.query.id; // 获取id参数的值
    // console.log(id);

    this.shangjia_Id = id

    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios.post("https://api.dfwlkeji.com/dindang/chaxun", {}, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑
        // console.log("我是列表的");

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });



        }

        console.log(response);



        // 新数组，用于存储当天的时间字符串
        // var newArray = [];
        var DT_dantian_shuju = []  // 过滤当天日期的  大作用


        // 获取当前日期
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，需要加1
        var currentDay = currentDate.getDate();


        for (let i = 0; i < response.data.data.length; i++) {
          // const element = response.data.data[i.DD_xiadanshijian];
          // console.log(response.data.data[i].DD_xiadanshijian);

          var dateFromString = new Date(response.data.data[i].DD_xiadanshijian);

          // // 获取数组中时间的年、月、日
          var year = dateFromString.getFullYear();
          var month = dateFromString.getMonth() + 1;
          var day = dateFromString.getDate();

          console.log(year, month, day);

          // 判断是否是当天日期
          if (currentYear === year && currentMonth === month && currentDay === day) {
            DT_dantian_shuju.push(response.data.data[i]);
          }



        }


        // console.log(DT_dantian_shuju);  // 过滤日期的


        const shuju_xianshi_wancheng = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 6);  //得到所有已完成订单
        // console.log(newArray);
        this.shuju_xianshi_wancheng = shuju_xianshi_wancheng.length  //设置已完成数量

        var xianshi_shifu = 0 //累计实付金额

        for (let WC = 0; WC < shuju_xianshi_wancheng.length; WC++) {
          const element = shuju_xianshi_wancheng[WC].YH_shifu;

          xianshi_shifu = xianshi_shifu + element

        }

        // console.log(xianshi_shifu);

        this.xianshi_shifu = xianshi_shifu



        //  待调度的 数量

        const daidiaodushuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 1);  //
        // console.log(newArray);
        this.daidiaodud_shuliang = daidiaodushuliang.length  //调度的


        //









        this.suoyoudingdan = DT_dantian_shuju
        this.yichaoshi_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai == 5).length;  // 过滤已超时的数量
        this.yiquxiao_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8).length;  // 过滤已取消的数量
        this.yiquxiao = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8);  // 过滤已取消的



        this.orderList = response.data.data;

        const newArray = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai < 6 || item.DD_zhuangtai == 7);  // 过滤全部数量的 除去未支付的
        this.orderList = newArray

        this.quanbu_shuliang = newArray.length







        // console.log(arr);

        //显示最新订单
        this.orderList.sort((a, b) => b.DD_id - a.DD_id);











      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);
      });



  },





  methods: {


    //  点击完成订单事件  + 订阅消息 通知的

    dianji_wancheng(id, zt) {

      console.log('完成订单');

      console.log(id);


      this.$confirm('确定完成订单？', ' 提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {


        let token = localStorage.getItem('token');

        var headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: token,
          Accept: "*/*",
          "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
          Host: "kaifa.beilakeji.top",
          "Accept-Encoding": "gzip, deflate, br",
          Connection: "keep-alive",
        };

        axios.post("https://api.dfwlkeji.com/shijian_wanc", {

          id: id


        }, { headers: headers }).then((res) => {

          console.log(res.data.data.msg);
          if (res.data.data == "商家不存在") {

            console.log(res.data);
            var sjid = res.data.shangjiaID
            alert(res.data.data + ',商家ID:' + sjid)



            this.$message({

              type: 'success',
              message: '商家不存在!'

            });



          } else {

            alert(res.data.data.msg)

          }









        }).catch((err) => {

          console.log(err);
        })















        // // 订阅通知

        const dd_xinxi = this.orderList.filter(item => item.DD_id == id);  //

        console.log(dd_xinxi[0]);

        const dingdan = dd_xinxi[0]

        axios.get('https://api.dfwlkeji.com/api/acc_wancheng?openid=' + dingdan.YH_goumairen_id + '&ddbh=' + dingdan.DD_dingdanbianhao + '&shijain=' + dingdan.QS_jiedanshijian + '&shangjianname=' + dingdan.SH_name + '&beizhu=如有问题请联系在线客服,感谢使用本平台!&shouhuo_name=' + dingdan.YH_xiaddanrenxinxi + '&qhdizhi=' + dingdan.YH_dizhi)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.error(error);
          });









        console.log('111111111111');
        this.quanbudingdan()  // 重新加载..
        this.quanbudingdan()  // 重新加载..





        this.$message({

          type: 'success',
          message: '已完成!'

        });




      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });





    },



    //点击显示已取消的订单

    xianshi_yiquxiao() {

      this.orderList = this.yiquxiao

    },



    //点击执行取消订单

    zhixing_quxiaodingdan(ddid, zhuangtai, danhao, shifu) {

      console.log(ddid); //  订单id

      const QS_gaiqishou = this.orderList.filter(item => item.DD_id == ddid);  // 过滤全部数量的 除去未支付的

      this.$confirm('确定取消<' + QS_gaiqishou[0].SH_name + ">该订单吗？", ' 提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        // 写接口更改


        // 支付状态不能等于0
        if (zhuangtai != 0) {

          console.log('执行退款！');

          function getData(url) {
            return new Promise((resolve, reject) => {
              fetch(url, {

              })
                .then(response => {
                  if (!response.ok) {
                    throw new Error('Network response was not ok');
                  }
                  return response.json();
                })
                .then(data => {
                  resolve(data);
                })
                .catch(error => {
                  reject(error);
                });
            });
          }



          // let token = localStorage.getItem('token');
          console.log(ddid, zhuangtai, danhao, shifu);
          var miaosu = '非常抱歉,商家打烊或其他原因,如有疑问请联系在平台客服'



          // 使用示例
          getData('https://api.dfwlkeji.com/wx/tk?danhao=' + danhao.toString() + '&kuan=' + shifu.toFixed(2) + '&yuanyin=' + miaosu)
            .then(res => {
              console.log('成功获取数据:', res);
            })
            .catch(error => {
              console.error('获取数据时出错:', error);
            });

          var jiegou = quxiao_dindan(ddid)

          console.log(jiegou);


          this.$message({

            type: 'success',
            message: '取消成功!'

          });




        } //



        //  取消订阅通知

        console.log('发起退款通知...');

        const dd_xinxi = this.orderList.filter(item => item.DD_id == ddid);  //获取当前订单

        console.log(dd_xinxi[0]);

        const dingdan = dd_xinxi[0]

        axios.get("https://api.dfwlkeji.com/api/acc_tuikuan?openid="+ dingdan.YH_goumairen_id + "&ddbh="+ dingdan.DD_dingdanbianhao + "&shangjianame="+ dingdan.SH_name + "&zhuangtai=订单已取消&yuanyin=商家已打烊或已售完&jinr="+dingdan.YH_shifu)
          .then((res) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.error(error);
          });


      
          this.quanbudingdan()

          this.quanbudingdan()







      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消接单'
        });
      });






    },















    // 订单执行调度或者接单 id为1时执行调度命令 id为7时 执行接单事件
    // 打开弹窗 //获取骑手数据 接单
    zhixing_diaodu(ddid, zhuangtai) {

      console.log(ddid, zhuangtai);// 订单id

      if (zhuangtai == 1 || zhuangtai == 2 || zhuangtai == 3 || zhuangtai == 4 || zhuangtai == 5) {

        //1的改为2
        console.log('执行调度');

        this.diaodu_TC = true  // 显示弹窗----------

        this.diaodu_ddID = ddid

        // 从浏览器的本地存储中获取token
        let token = localStorage.getItem('token');

        // console.log(token);

        var headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            token,
          Accept: "*/*",
          "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
          Host: "kaifa.beilakeji.top",
          "Accept-Encoding": "gzip, deflate, br",
          Connection: "keep-alive",
        };

        //  查询骑手 获取骑手列表
        axios.post("https://api.dfwlkeji.com/qishou/chaxun", {


        }, { headers: headers })

          .then((response) => {
            // 请求成功处理逻辑


            // console.log("我是列表的");

            if (response.data.message == "身份认证失败！th验证失败") {

              console.log("mytk");
              this.$router.push({ path: "../Loin" });



            }

            // console.log(response);

            this.sy_shuju = response.data.data


            const zhengchang = response.data.data.filter(item => item.QS_ZHzhuangtai == 1); //过滤以拉黑的 骑手

            const zaixiand = zhengchang.filter(item => item.QS_GZzhuangtai == 1)

            // console.log(zaixiand);

            this.zaixiand_SHUJU = zaixiand



            // setInterval(() => {
            //   this.refreshData();
            // }, 100);



          })
          .catch((error) => {
            // 请求失败处理逻辑

            // console.error(error);
          });











      }




      if (zhuangtai == 7) {


        console.log('执行接单事件');
        this.$confirm('确定替该商家接单吗？', ' 提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {


          var res = qq_jied(ddid)  // 接单的---------------------------

          console.log(res);

          this.$message({

            type: 'success',
            message: '接单成功!'

          });


          console.log('111111111111');
          this.quanbudingdan()  // 重新加载..
          this.quanbudingdan()  // 重新加载..
          this.quanbudingdan()  // 重新加载..






          // 在你的方法中调用以下代码
          // window.location.reload();



        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消接单'
          });
        });



      }



    },



    //调度接口->骑手id
    ZX_DIAODU(id) {

      console.log(this.diaodu_ddID, '订单id...');
      console.log(id, '这是id..............');







      // dingyue_diaodu('owm9y6z3wJTrx0Sf_vl94GyYbKo0',dd_xinxi[0].DD_dingdanbianhao,dd_xinxi[0].QS_jiedanshijian,dd_xinxi[0].SH_name,dd_xinxi[0].QS_name).then((res)=>{

      //   console.log(res);


      // })



      // dd_xinxi[0].DD_dingdanbianhao 
      // YH_goumairen_id
      // QS_jiedanshijian
      // QS_name
      // QS_shouji






      // this.orderList



      // console.log('确认调度', this.diaodu_ddID, id);
      const QS_gaiqishou = this.zaixiand_SHUJU.filter(item => item.QS_id == id);  // 过滤全部数量的 除去未支付的

      console.log(QS_gaiqishou[0].QS_name);

      // 这条信息的订单信息
      const dd_xinxi = this.orderList.filter(item => item.DD_id == this.diaodu_ddID);  //

      console.log(dd_xinxi[0]);

      const dingdan = dd_xinxi[0]

      console.log(dingdan.DD_dingdanbianhao, dingdan.QS_jiedanshijian, dingdan.SH_name, QS_gaiqishou[0].QS_name);

      axios.get("https://api.dfwlkeji.com/api/acc_tiaodu?openid=" + dingdan.YH_goumairen_id + "&ztnr=骑手已取货&ddbh=" + dingdan.DD_dingdanbianhao + "&shijain=" + dingdan.QS_jiedanshijian + "&shangjianname=" + dingdan.SH_name + "&qishouxinxi=" + QS_gaiqishou[0].QS_name)
        .then((res) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.error(error);
        });


      // 获取骑手名称
      const jiekou = "diaodu"

      const can = {

        qishou_id: id,

        qishou_name: QS_gaiqishou[0].QS_name,

        qishou_shouji: QS_gaiqishou[0].QS_shouji,

        id: this.diaodu_ddID



      }




      this.gongong(jiekou, can)

      this.diaodu_TC = false


      // this.quanbudingdan()










    },


    //点击显示全部订单
    quanbudingdan() {

      let token = localStorage.getItem('token');

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios.post("https://api.dfwlkeji.com/dindang/chaxun", {}, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          // console.log(response);



          // 新数组，用于存储当天的时间字符串
          // var newArray = [];
          var DT_dantian_shuju = []  // 过滤当天日期的  大作用


          // 获取当前日期
          var currentDate = new Date();
          var currentYear = currentDate.getFullYear();
          var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，需要加1
          var currentDay = currentDate.getDate();


          for (let i = 0; i < response.data.data.length; i++) {
            // const element = response.data.data[i.DD_xiadanshijian];
            // console.log(response.data.data[i].DD_xiadanshijian);

            var dateFromString = new Date(response.data.data[i].DD_xiadanshijian);

            // // 获取数组中时间的年、月、日
            var year = dateFromString.getFullYear();
            var month = dateFromString.getMonth() + 1;
            var day = dateFromString.getDate();

            // console.log(year, month, day);

            // 判断是否是当天日期
            if (currentYear === year && currentMonth === month && currentDay === day) {
              DT_dantian_shuju.push(response.data.data[i]);
            }



          }


          // console.log(DT_dantian_shuju);  // 过滤日期的


          const shuju_xianshi_wancheng = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 6);  //得到所有已完成订单
          // console.log(newArray);
          this.shuju_xianshi_wancheng = shuju_xianshi_wancheng.length  //设置已完成数量

          var xianshi_shifu = 0 //累计实付金额

          for (let WC = 0; WC < shuju_xianshi_wancheng.length; WC++) {
            const element = shuju_xianshi_wancheng[WC].YH_shifu;

            xianshi_shifu = xianshi_shifu + element

          }

          // console.log(xianshi_shifu);

          this.xianshi_shifu = xianshi_shifu



          //  待调度的 数量

          const daidiaodushuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 1);  //
          // console.log(newArray);
          this.daidiaodud_shuliang = daidiaodushuliang.length  //调度的


          //


          this.suoyoudingdan = DT_dantian_shuju
          this.yichaoshi_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai == 5).length;  // 过滤已超时的数量
          this.yiquxiao_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8).length;  // 过滤已取消的数量
          this.yiquxiao = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8);  // 过滤已取消的

          this.orderList = response.data.data;
          const newArray = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai < 6 || item.DD_zhuangtai == 7);  // 过滤全部数量的 除去未支付的
          this.orderList = newArray

          this.quanbu_shuliang = newArray.length

          // console.log(arr);

          //显示最新订单
          this.orderList.sort((a, b) => b.DD_id - a.DD_id);











        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });


    },




    //  未支付的订单
    weizhifu() {

      console.log(this.suoyoudingdan);


      const newArray = this.suoyoudingdan.filter(item => item.DD_zhuangtai == 0);

      console.log(newArray);

      this.orderList = newArray



    },


    //已超时的

    yichaoshi() {

      console.log(this.suoyoudingdan);


      const newArray = this.suoyoudingdan.filter(item => item.DD_zhuangtai == 5);

      console.log(newArray);

      this.orderList = newArray



    },



    //待配送

    dianji_daipeisong() {

      // console.log(this.suoyoudingdan);


      const newArray = this.suoyoudingdan.filter(item => item.DD_zhuangtai == 1);

      // console.log(newArray);

      this.orderList = newArray



    },




    //  点击配送中的
    dianji_peisongzhong() {

      console.log(this.suoyoudingdan);


      const newArray = this.suoyoudingdan.filter(item => item.DD_zhuangtai == 2);

      // console.log(newArray);

      this.orderList = newArray



    },



    //  点击已完成的
    dianji_yiwancheng() {

      console.log(this.suoyoudingdan);


      const newArray = this.suoyoudingdan.filter(item => item.DD_zhuangtai == 6);

      // console.log(newArray);

      this.orderList = newArray



    },










    //  加载所以订单

    jiazaisuoyou() {


      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios.post("https://api.dfwlkeji.com/dindang/chaxun", {}, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          console.log(response);



          // 新数组，用于存储当天的时间字符串
          // var newArray = [];
          var DT_dantian_shuju = []  // 过滤当天日期的  大作用


          // 获取当前日期
          var currentDate = new Date();
          var currentYear = currentDate.getFullYear();
          var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，需要加1
          var currentDay = currentDate.getDate();


          for (let i = 0; i < response.data.data.length; i++) {
            // const element = response.data.data[i.DD_xiadanshijian];
            // console.log(response.data.data[i].DD_xiadanshijian);

            var dateFromString = new Date(response.data.data[i].DD_xiadanshijian);

            // // 获取数组中时间的年、月、日
            var year = dateFromString.getFullYear();
            var month = dateFromString.getMonth() + 1;
            var day = dateFromString.getDate();

            console.log(year, month, day);

            // 判断是否是当天日期
            if (currentYear === year && currentMonth === month && currentDay === day) {
              DT_dantian_shuju.push(response.data.data[i]);
            }



          }


          // console.log(DT_dantian_shuju);  // 过滤日期的


          const shuju_xianshi_wancheng = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 6);  //得到所有已完成订单
          // console.log(newArray);
          this.shuju_xianshi_wancheng = shuju_xianshi_wancheng.length  //设置已完成数量

          var xianshi_shifu = 0 //累计实付金额

          for (let WC = 0; WC < shuju_xianshi_wancheng.length; WC++) {
            const element = shuju_xianshi_wancheng[WC].YH_shifu;

            xianshi_shifu = xianshi_shifu + element

          }

          // console.log(xianshi_shifu);

          this.xianshi_shifu = xianshi_shifu



          //  待调度的 数量

          const daidiaodushuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 1);  //
          // console.log(newArray);
          this.daidiaodud_shuliang = daidiaodushuliang.length  //调度的


          //









          this.suoyoudingdan = DT_dantian_shuju
          this.yichaoshi_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai == 5).length;  // 过滤已超时的数量
          this.yiquxiao_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8).length;  // 过滤已取消的数量
          this.yiquxiao = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8);  // 过滤已取消的



          this.orderList = response.data.data;

          const newArray = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai < 6 || item.DD_zhuangtai == 7);  // 过滤全部数量的 除去未支付的
          this.orderList = newArray

          this.quanbu_shuliang = newArray.length







          // console.log(arr);

          //显示最新订单
          this.orderList.sort((a, b) => b.DD_id - a.DD_id);











        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });



    },





    gongong(jiekou, can) {


      // 在组件创建时执行
      console.log("组件创建");

      // const id = this.$route.query.id; // 获取id参数的值
      // // console.log(id);

      // this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/dindang/" + jiekou, can, { headers: headers })

        .then((response) => {

          // 请求成功处理逻辑

          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }




          // 新数组，用于存储当天的时间字符串
          // var newArray = [];
          var DT_dantian_shuju = []  // 过滤当天日期的  大作用


          // 获取当前日期
          var currentDate = new Date();
          var currentYear = currentDate.getFullYear();
          var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，需要加1
          var currentDay = currentDate.getDate();


          for (let i = 0; i < response.data.data.length; i++) {
            // const element = response.data.data[i.DD_xiadanshijian];
            // console.log(response.data.data[i].DD_xiadanshijian);

            var dateFromString = new Date(response.data.data[i].DD_xiadanshijian);

            // // 获取数组中时间的年、月、日
            var year = dateFromString.getFullYear();
            var month = dateFromString.getMonth() + 1;
            var day = dateFromString.getDate();

            console.log(year, month, day);

            // 判断是否是当天日期
            if (currentYear === year && currentMonth === month && currentDay === day) {
              DT_dantian_shuju.push(response.data.data[i]);
            }



          }


          const newArray = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai < 6 || item.DD_zhuangtai == 7);  // 过滤全部数量的 除去未支付的





          //显示最新订单
          this.orderList.sort((a, b) => b.DD_id - a.DD_id);




          this.orderList = newArray






        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });


    },









    refreshData() {

      console.log(this.zhidong_shuaxin);

      if (this.zhidong_shuaxin) {


        // 在组件创建时执行
        // console.log("组件创建");


        // 从浏览器的本地存储中获取token
        let token = localStorage.getItem('token');

        // console.log(token);

        var headers = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization:
            token,
          Accept: "*/*",
          "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
          Host: "kaifa.beilakeji.top",
          "Accept-Encoding": "gzip, deflate, br",
          Connection: "keep-alive",
        };

        axios
          .post("https://api.dfwlkeji.com/dindang/chaxun", {}, { headers: headers })

          .then((response) => {
            // 请求成功处理逻辑


            // console.log("我是列表的");

            if (response.data.message == "身份认证失败！th验证失败") {

              console.log("mytk");
              this.$router.push({ path: "../Loin" });



            }

            // console.log(response);



            // 新数组，用于存储当天的时间字符串
            // var newArray = [];
            var DT_dantian_shuju = []  // 过滤当天日期的  大作用


            // 获取当前日期
            var currentDate = new Date();
            var currentYear = currentDate.getFullYear();
            var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，需要加1
            var currentDay = currentDate.getDate();


            for (let i = 0; i < response.data.data.length; i++) {

              var dateFromString = new Date(response.data.data[i].DD_xiadanshijian);
              // // 获取数组中时间的年、月、日
              var year = dateFromString.getFullYear();
              var month = dateFromString.getMonth() + 1;
              var day = dateFromString.getDate();

              console.log(year, month, day);

              // 判断是否是当天日期
              if (currentYear === year && currentMonth === month && currentDay === day) {
                DT_dantian_shuju.push(response.data.data[i]);
              }



            }


            // console.log(DT_dantian_shuju);  // 过滤日期的


            const shuju_xianshi_wancheng = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 6);  //得到所有已完成订单
            // console.log(newArray);
            this.shuju_xianshi_wancheng = shuju_xianshi_wancheng.length  //设置已完成数量

            var xianshi_shifu = 0 //累计实付金额

            for (let WC = 0; WC < shuju_xianshi_wancheng.length; WC++) {
              const element = shuju_xianshi_wancheng[WC].YH_shifu;

              xianshi_shifu = xianshi_shifu + element

            }

            // console.log(xianshi_shifu);

            this.xianshi_shifu = xianshi_shifu





            this.suoyoudingdan = DT_dantian_shuju
            this.yichaoshi_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai == 5).length;  // 过滤已超时的数量
            this.yiquxiao_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8).length;  // 过滤已取消的数量
            this.yiquxiao = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8);  // 过滤已取消的



            this.orderList = response.data.data;

            const newArray = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai < 6 || item.DD_zhuangtai == 7);  // 过滤全部数量的 除去未支付的
            this.orderList = newArray

            this.quanbu_shuliang = newArray.length






            //  待调度的 数量

            const daidiaodushuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 1);  //
            // console.log(newArray);
            this.daidiaodud_shuliang = daidiaodushuliang.length  //调度的


            //





            // console.log(arr);












          })
          .catch((error) => {
            // 请求失败处理逻辑

            // console.error(error);
          });

      }


    },












    //  正的加载刷新


    jiazai_shuax() {

      console.log('重新刷新');

      // 在组件创建时执行
      // console.log("组件创建");

      const id = this.$route.query.id; // 获取id参数的值
      // console.log(id);

      this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios.post("https://api.dfwlkeji.com/dindang/chaxun", {}, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          console.log(response);



          // 新数组，用于存储当天的时间字符串
          // var newArray = [];
          var DT_dantian_shuju = []  // 过滤当天日期的  大作用


          // 获取当前日期
          var currentDate = new Date();
          var currentYear = currentDate.getFullYear();
          var currentMonth = currentDate.getMonth() + 1; // 月份从0开始，需要加1
          var currentDay = currentDate.getDate();


          for (let i = 0; i < response.data.data.length; i++) {
            // const element = response.data.data[i.DD_xiadanshijian];
            // console.log(response.data.data[i].DD_xiadanshijian);

            var dateFromString = new Date(response.data.data[i].DD_xiadanshijian);

            // // 获取数组中时间的年、月、日
            var year = dateFromString.getFullYear();
            var month = dateFromString.getMonth() + 1;
            var day = dateFromString.getDate();

            console.log(year, month, day);

            // 判断是否是当天日期
            if (currentYear === year && currentMonth === month && currentDay === day) {
              DT_dantian_shuju.push(response.data.data[i]);
            }



          }


          // console.log(DT_dantian_shuju);  // 过滤日期的


          const shuju_xianshi_wancheng = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 6);  //得到所有已完成订单
          // console.log(newArray);
          this.shuju_xianshi_wancheng = shuju_xianshi_wancheng.length  //设置已完成数量

          var xianshi_shifu = 0 //累计实付金额

          for (let WC = 0; WC < shuju_xianshi_wancheng.length; WC++) {
            const element = shuju_xianshi_wancheng[WC].YH_shifu;

            xianshi_shifu = xianshi_shifu + element

          }

          // console.log(xianshi_shifu);

          this.xianshi_shifu = xianshi_shifu



          //  待调度的 数量

          const daidiaodushuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 1);  //
          // console.log(newArray);
          this.daidiaodud_shuliang = daidiaodushuliang.length  //调度的


          //









          this.suoyoudingdan = DT_dantian_shuju
          this.yichaoshi_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai == 5).length;  // 过滤已超时的数量
          this.yiquxiao_shuliang = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8).length;  // 过滤已取消的数量
          this.yiquxiao = DT_dantian_shuju.filter(item => item.DD_zhuangtai == 8);  // 过滤已取消的



          this.orderList = response.data.data;

          const newArray = DT_dantian_shuju.filter(item => item.DD_zhuangtai > 0 && item.DD_zhuangtai < 6 || item.DD_zhuangtai == 7);  // 过滤全部数量的 除去未支付的
          this.orderList = newArray

          this.quanbu_shuliang = newArray.length







          // console.log(arr);

          //显示最新订单
          this.orderList.sort((a, b) => b.DD_id - a.DD_id);











        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });



    },






















  },





};
</script>

<style scoped>
.order-list {
  border: 0px solid #119e5c;
  border-radius: 5px;
  padding: 1px;
  width: 80%;
  height: 600px;
  margin-top: 100px;

}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.order-header-info {
  flex: 1px;
}

.order-actions button {
  margin-left: 5px;
  margin-top: 10px;
}

.order-item {
  border-top: 10px solid #409EFF;
  border-bottom: 1px solid #409EFF;
  border-left: 1px solid #409EFF;
  border-right: 1px solid #409EFF;

  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  background: #eaeaf0;
}

.order-info {
  flex: 1;
}
</style>