<template>
  <div>
    <h2>编辑商品</h2>
    <el-form :model="product" label-width="120px">
      <el-form-item label="排序">
        <el-input-number v-model="product.sortOrder"></el-input-number>
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input v-model="product.name"></el-input>
      </el-form-item>
      <el-form-item label="价格">
        <el-input-number v-model="product.price"></el-input-number>
      </el-form-item>
      <el-form-item label="规格">
        <el-input v-model="product.specification"></el-input>
      </el-form-item>
      <el-form-item label="属性">
        <el-input v-model="product.attributes"></el-input>
      </el-form-item>
      <el-form-item label="分类">
        <el-input v-model="product.category"></el-input>
      </el-form-item>
      <el-form-item label="单位">
        <el-input v-model="product.unit"></el-input>
      </el-form-item>
      <el-form-item label="餐盒费">
        <el-input-number v-model="product.packagingFee"></el-input-number>
      </el-form-item>
      <el-form-item label="商品图片">
        <el-input v-model="product.image"></el-input>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" v-model="product.description"></el-input>
      </el-form-item>
      <el-form-item label="库存">
        <el-input-number v-model="product.stock"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveProduct">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: {
        sortOrder: 0,
        name: '',
        price: 0,
        specification: '',
        attributes: '',
        category: '',
        unit: '',
        packagingFee: 0,
        image: '',
        description: '',
        stock: 0
      }
    };
  },
  methods: {
    saveProduct() {
      // 保存商品逻辑
      console.log('保存商品:', this.product);
    }
  }
};
</script>

<style scoped>
/* 可选的样式 */
</style>