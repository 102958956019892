<template>
  <div>
    <!-- 搜索框 -->

    <el-container>
      <el-header>

        <div class="container">


          <!-- /////添加商家 -->



          <!-- <el-button type="primary" icon="el-icon-plus" @click="openQitePage">添加商户</el-button> -->

          <el-button type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">添加骑手</el-button>




          <el-button type="success" icon="el-icon-refresh" style="margin-top: 0px; margin-left: 50px;"
            @click="quanbushangjia" plain>显示所有</el-button>

          <el-button type="info" icon="el-icon-delete-solid" style="margin-top: 0px; margin-left: 50px;"
            @click="huishouzhan" plain>回收站</el-button>




          <!-- 修改的弹窗 -->
          <el-dialog title="修改骑手信息" @click="fenlei_tc = false" :visible.sync="fenlei_tc">

            <div style="display: flex;
                justify-content: center;
                align-items: center;
                height: 20vh; /* 设置容器高度为视口高度，使表单垂直居中 */">

              <div style="width: 100%;">


                <div style="width: 100%;  margin-bottom: 15px;">


                  <label for="category2" style="margin-left: 0px;">姓名：</label>

                  <input style="width: 100px;" class="shur" type="text" id="qs_name" v-model="Xqs_name" required>


                  <label for="category2" style="margin-left: 60px;">性别：</label>

                  <select style="width: 100px;" class="shur" id="category2" v-model="Xqs_xingbie" required>

                    <option value="男">男</option>
                    <option value="女">女</option>
                    <option value="未知">未知</option>

                  </select>

                </div>


                <div style="width: 100%; margin-bottom: 15px; ">

                  <label for="category2" style="margin-left: 0px;">手机：</label>

                  <input style="width: 120px; " class="shur" type="mnumber" id="qs_shouji" v-model="Xqs_shouji" required>

                  <label for="category2" style="margin-left: 17px;"> 身份证号:</label>

                  <input style="width: 120px; " class="shur" type="mnumber" id="qs_shouji" v-model="Xqs_sfz" required>


                </div>

                <div style="width: 100%; margin-bottom: 15px; ">

                  <label for="category2" style="margin-left: 0px;">密码：</label>

                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="Xqs_mima" required>

                  <label for="category2" style="margin-left: 40px;"> 年龄：</label>

                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="Xqs_nianling" required>



                </div>


                <div style="width: 100%; margin-bottom: 15px; ">

                  <label for="category2" style="margin-left: 0px;">支付宝账号</label>

                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="zhifubao" required>

                  <label for="category2" style="margin-left: 40px;">支付宝实名</label>

                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="zhifubaoname" required>

                  <label for="category2" style="margin-left: 40px;">起步价</label>

                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="qibujia" required>


                </div>







                <button type="submit"
                  @click="XIUGAI_Tj(Xqs_name, Xqs_xingbie, Xqs_shouji, Xqs_sfz, Xqs_mima, Xqs_nianling, zhifubao, zhifubaoname, qibujia)"
                  class="submit-button">提交</button>


              </div>


            </div>

          </el-dialog>



          <!-- 新建的弹窗 -->
          <el-dialog title="添加骑手" @click="dialogFormVisible = false" :visible.sync="dialogFormVisible">





            <div style="display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 设置容器高度为视口高度，使表单垂直居中 */">



              <form @submit.prevent="handleSubmit" class="form" style=" width: 100%; margin-top:70px;">


                <div style="width: 100%;  margin-bottom: 15px;">


                  <label for="category2" style="margin-left: 0px;">姓名：</label>

                  <input style="width: 100px;" class="shur" type="text" id="qs_name" v-model="qs_name" required>


                  <label for="category2" style="margin-left: 10px;">性别：</label>

                  <select style="width: 100px;" class="shur" id="category2" v-model="qs_xingbie" required>

                    <option value="男">男</option>
                    <option value="女">女</option>
                    <option value="未知">未知</option>

                  </select>

                </div>


                <div style="width: 100%; margin-bottom: 15px; ">

                  <label for="category2" style="margin-left: 0px;">手机：</label>

                  <input style="width: 120px; " class="shur" type="mnumber" id="qs_shouji" v-model="qs_shouji" required>

                  <label for="category2" style="margin-left: 50px;">密码：</label>

                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="qs_mima" required>


                  <label for="category2" style="margin-left: 50px;">起步价：</label>
                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="qibujia" required>







                </div>

                <div style="width: 100%; margin-bottom: 15px; ">


                </div>



                <div style="width: 100%; margin-bottom: 15px; ">


                  <label for="category2" style="margin-left: 0px;">支付宝账号</label>
                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="zhifubao" required>
                  <label for="category2" style="margin-left: 50px;">支付宝实名：</label>
                  <input style="width: 120px;" class="shur" type="text" id="qs_mima" v-model="zhifubaoname" required>



                </div>



                <div style="width: 100%; margin-bottom: 15px; ">



                </div>










                <button type="submit" class="submit-button">提交</button>

              </form>

            </div>

            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <!-- <el-button type="primary" @click="navigateToNextPage">确 定</el-button> -->
            </div>



          </el-dialog>










        </div>
      </el-header>

      <el-main>
        <!-- /////表格 -->

        <div>
          <!-- <el-table :data="shangjia" style="width: 100%;" fixed="left" > -->

          <el-table :data="shangjia" style="width: 100%" max-height="670">

            <el-table-column fixed prop="QS_id" label="编号" width="100px">
            </el-table-column>







            <el-table-column prop="QS_name" label="名称" width='120px'>
            </el-table-column>


            <el-table-column prop="QS_shouji" label="手机" width='170px'>
            </el-table-column>

            <el-table-column prop="QS_xingbie" label="性别" width='100px' style="float: center;">


            </el-table-column>

            <el-table-column prop="QS_nianling" label="年龄" width='80px' style="float: center;">


            </el-table-column>



            <el-table-column prop="QS_yue" label="余额" width='100px' style="float: center;">
            </el-table-column>



            <el-table-column prop="QS_shijian" label="绑定时间" width='130px' style="float: center;">
            </el-table-column>





            <el-table-column prop="QS_GZzhuangtai" label="工作状态" width="250px">
              <template slot-scope="scope">
                <el-switch :value="scope.row.QS_GZzhuangtai === 1" active-text="上线" inactive-text="休息"
                  @change="SHEZHI_GONGZUO(scope.row.QS_id, scope.row.QS_GZzhuangtai)"></el-switch>
              </template>
            </el-table-column>





            <el-table-column label="管理" width="225px">


              <template slot-scope="scope">
                <!-- <input type="button" value="管理" @click="handleManage(scope.row.vendor_ID)"> -->
                <el-button type="mini" icon="el-icon-setting" @click="handleManage(scope.row.QS_id)">账号明细</el-button>

                <el-button type="mini" icon="el-icon-upload2" @click="handleManage(scope.row.QS_id)">账号变动</el-button>

              </template>



            </el-table-column>



            <el-table-column label="操作" width="225px">


              <template slot-scope="scope">
                <!-- <input type="button" value="管理" @click="handleManage(scope.row.vendor_ID)"> -->
                <el-button type="mini" icon="el-icon-s-order" @click="handleManage(scope.row.QS_id)">编辑</el-button>

                <el-button type="mini" :class="scope.row.QS_ZHzhuangtai == 1 ? 'el-icon-delete' : 'el-icon-magic-stick'"
                  @click="shanchushangjia(scope.row.QS_id, scope.row.QS_ZHzhuangtai)">


                  {{ scope.row.QS_ZHzhuangtai === 1 ? '拉黑' : '恢复' }}</el-button>


              </template>



            </el-table-column>


          </el-table>
        </div>






      </el-main>





    </el-container>
  </div>
</template>

<script>
import axios from "axios";



export default {
  data() {
    return {

      xg_fenlei_name: '', //修改时弹窗显示的
      xg_fenlei_xuhao: '',
      //全部骑手列表数据
      sy_shuju: '',


      shangjia: "",//  分类列表数据

      zhifubaoname: '',//支付宝名称
      zhifubao: '',//支付宝名称
      qibujia: '',//起步价




      jieshoushuju: "",
      value: "100",
      zhuangtai: true,
      dialogTableVisible: false,
      dialogFormVisible: false,
      fenlei_tc: false,
      form: {
        name: '',
        dianhua: '',
        xuhao: '',
        logo: '',
        fenlei: {
          zhu: "",
          fu: ""
        },





      },

      fenleiweiyiID: '',// 分类唯一的id

      shangjia_Id: '', // 分类所属商家的id


      previewURL: '', //logo

      sousuo: {
        ssgjc: ''


      },

      uploadUrl: 'https://example.com/upload', // 替换为你的上传接口URL


      formLabelWidth: '120px'
    };
  },

  /////



  // watch: {
  //   value(newValue) {
  //     // 在开关状态改变时触发
  //     console.log("开关状态改变:", newValue);
  //   },

  // },

  created() {

    // 在组件创建时执行
    console.log("组件创建");



    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    // console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/qishou/chaxun", {



      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑


        // console.log("我是列表的");

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });



        }

        console.log(response);

        this.sy_shuju = response.data.data


        //  从大到小排列
        response.data.data.sort(function (a, b) {
          return b.Fenlei_xuhao - a.Fenlei_xuhao;
        });


        const zhengchang = response.data.data.filter(item => item.QS_ZHzhuangtai == 1); //过滤以拉黑的

        console.log(zhengchang);

        this.shangjia = zhengchang






        // this.shangjia = response.data.data;


        // console.log(arr);












      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);
      });
  },


  mounted() {
    // 在组件挂载到DOM后执行
    console.log("组件挂载");
    this.startTimer();
  },


  updated() {
    // 在组件更新后执行
    console.log("组件更新");
  },

  destroyed() {
    // 在组件销毁前执行
    console.log("组件销毁");
    this.stopTimer();
  },

  methods: {



    // 点击回收站

    huishouzhan() {


      console.log(this.sy_shuju);


      const huishou = this.sy_shuju.filter(item => item.QS_ZHzhuangtai == 2); //过滤以拉黑的

      console.log(huishou);

      this.shangjia = huishou


    },


    //设置工作状态

    SHEZHI_GONGZUO(id, zg_zt) {

      console.log(id, zg_zt);

      let can

      if (zg_zt == 0) {

        can = 1

      } else {

        can = 0


      }



      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/qishou/gzzt", {

          id: id,
          can: can


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          };



          console.log(response);

          this.jiazaishangjialiebiao()











        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });








    },



    //  提交骑手修改分类

    XIUGAI_Tj(name, xingbie, shouji, sfz, mima, nianling, zhifubao, zhifubaoname, qibujia) {

      // console.log(1111111111111111);

      // console.log(this.fenleiweiyiID);


      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,

        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/qishou/xiugai", {



          name: name,
          xingbie: xingbie,
          shouji: shouji,
          sfz: sfz,
          mima: mima,
          id: this.fenleiweiyiID,
          nianling: nianling,

          zhifubao: zhifubao,

          zhifubaoname: zhifubaoname,

          qibujia: qibujia,





        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });

          }

          console.log(response);

          this.shangjia = response.data.data;


          this.fenlei_tc = false  // 关闭修改分类的弹窗

          this.$message.success('修改成功');

          this.jiazaishangjialiebiao()













        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });
















    },



    // 上传logo

    handleFileChange(event) {

      this.imageFile = event.target.files[0];
      const file = event.target.files[0];

      console.log(this.imageFile);


      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewURL = e.target.result;
      };

      reader.readAsDataURL(file);








    },



    //---- 添加骑手

    handleSubmit() {

      // console.log('tij');
      console.log(this.qs_name);
      console.log(this.qs_shouji);
      console.log(this.qs_xingbie);
      console.log(this.qs_mima);

      const currentTime = new Date(); // 当前时间


      const date = currentTime.toLocaleDateString(); // 获取日期部分
      const time = currentTime.toLocaleTimeString(); // 获取时间部分

      const formattedDateTime = `${date} ${time}`; // 拼接日期和时间

      console.log(formattedDateTime); // 输出格式化后的日期时间






      console.log("加载分类列表");


      const id = this.$route.query.id; // 获取id参数的值
      // console.log(id);

      this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/qishou/xinjian", {



          name: this.qs_name,
          shouji: this.qs_shouji,
          xingbie: this.qs_xingbie,
          mima: this.qs_mima,
          shijian: formattedDateTime,
          zhifubaoname: this.zhifubaoname,
          zhifubao: this.zhifubao,
          qibujia: this.zhifubao,













        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          console.log(response);

          // this.shangjia = response.data.data;

          this.dialogFormVisible = false  // 关闭弹窗

          this.$message.success('添加成功');



          setTimeout(function () {
            location.reload();
          }, 500);


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });








    },







    // 点击全部商家

    quanbushangjia() {


      this.jiazaishangjialiebiao()

    },








    //删除分类
    shanchushangjia(id, ZG_zhuangtai) {


      //   // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('删除商家id:', id);

      // console.log(this.shangjia);

      var sjmc = null
      //  提取名称
      for (var i = 0; i < this.shangjia.length; i++) {
        console.log(this.shangjia[i].QS_id
        );

        if (this.shangjia[i].QS_id == id) {

          sjmc = this.shangjia[i].QS_name

          break


        }
      }


      //  拉黑的
      if (ZG_zhuangtai == 1) {


        this.$confirm('确定拉黑该骑手吗？ 《 ' + sjmc + " 》 ", ' 提示', {
          confirmButtonText: '拉黑',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {




          this.shanchuhanshu(id, 2) // 删除商家







          this.$message({

            type: 'success',
            message: '删除成功!'

          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消拉黑'
          });
        });


      }

      //  恢复的
      if (ZG_zhuangtai == 2) {


        this.$confirm('确定恢复该骑手吗？ 《 ' + sjmc + " 》 ", ' 提示', {
          confirmButtonText: '恢复',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {




          this.shanchuhanshu(id, 1) // 恢复商家







          this.$message({

            type: 'success',
            message: '删除成功!'

          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消拉黑'
          });
        });


      }

    },




    //  删除的函数
    shanchuhanshu(id, zztt) {



      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/qishou/lahei", {

          id: id,
          zhuangtai: zztt


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

          // window.location.reload();
          this.jiazaishangjialiebiao()





        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });


    },





    //管理骑手

    handleManage(id) {


      // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('Manage button clicked for ID:', id);

      console.log('编辑骑手id:', id);

      this.fenleiweiyiID = id

      this.fenlei_tc = true

      console.log(this.shangjia);

      var result = this.shangjia.filter(function (obj) {
        return obj.QS_id === id;
      });

      console.log(result[0].QS_name);

      this.Xqs_name = result[0].QS_name
      this.Xqs_xingbie = result[0].QS_xingbie
      this.Xqs_shouji = result[0].QS_shouji
      this.Xqs_mima = result[0].QS_mima
      this.Xqs_sfz = result[0].QS_sfz
      this.Xqs_nianling = result[0].QS_nianling


      this.zhifubao = result[0].QS_zhifubao

      this.zhifubaoname = result[0].QS_zhifubaoname

      this.qibujia = result[0].QS_qibujia









    },














    //  加载骑手列表函数

    jiazaishangjialiebiao() {

      console.log("加载分类列表");


      const id = this.$route.query.id; // 获取id参数的值
      // console.log(id);

      this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/qishou/chaxun", {




        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          const zhengchang = response.data.data.filter(item => item.QS_ZHzhuangtai == 1); //过滤以拉黑的

          console.log(zhengchang);

          this.shangjia = zhengchang

          this.sy_shuju = response.data.data




        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });



    },

















  },
};
</script>

<style>
.container {
  margin-top: 39px;
}



.moveable-component {
  position: relative;
  top: 100px;
  left: -150px;
}

.moveable {
  top: -103px;
  bottom: 30px;
  transform: translate(150%, 105%);



}

.dianpumingcheng {

  position: relative;
  top: -50px;
  bottom: 50px;
  transform: translate(-15%, 10%);

}

.shangjiadianhua {

  position: relative;
  top: -90px;
  bottom: 50px;
  transform: translate(26%, 10%);

}


.dianshu {

  position: relative;
  top: -50px;
  bottom: 50px;
  transform: translate(26%, 10%);

}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* 调整容器的高度 */
  transform: translate(-40%, 10%);
}

.loading-image {
  width: 100px;
  /* 调整图片的宽度 */
  height: 100px;
  /* 调整图片的高度 */
}

.upload-demo {

  position: relative;
  top: -165px;
  bottom: 50px;
  transform: translate(40%, 10%);



}







.form-container {
  width: 100%;
  /* 设置表单宽度为100% */
  height: 100%;
  /* 设置表单高度为100% */
  overflow: hidden;
  /* 防止表单元素超出弹窗 */
}




/* //biaod */


.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

/* input,
select {

} */


.shur {

  padding: 8px;
  border: 1px solid #2fa9f0;
  border-radius: 5px;



}




.submit-button {
  padding: 10px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}



.file-input {
  position: relative;
  display: inline-block;
}

.input {
  position: absolute;
  left: -9999px;
}

.button {
  display: inline-block;
  padding: 8px 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 8px;

}


.button:hover {
  background-color: #0056b3;
  border-radius: 12px;
  color: rgb(247, 246, 250);


}

.file-name {
  margin-left: 6px;
}
</style>
