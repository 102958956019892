<template>
  <div>


    开启自动接单：
    <el-switch :value="zidong_jie" active-text="" inactive-text="" @change="auto_jiedan()"></el-switch>

    <hr>


    <p>商鹏打印机：</p>

    <div style="float: left;">

      机器码：
      <el-input v-model="s_p_id" size="mini" placeholder="机器码" style="width: 150px;"></el-input>

      key： <el-input v-model="s_p_key" size="mini" placeholder="key" style="width: 180px;"></el-input>

      机器名： <el-input v-model="s_p_name" size="mini" placeholder="key" style="width: 180px;"></el-input>

      <el-button type="success" round style="margin-left: 50px;" @click="tijiao_sp()">提交修改</el-button>
    </div>

    <br>
    <br>
    <br>


    <p>易联云打印机：</p>

    <div style="float: left;">

      机器码：
      <el-input v-model="yly_appid" size="mini" placeholder="机器码" style="width: 150px;"></el-input>
      key： <el-input v-model="yly_key" size="mini" placeholder="key" style="width: 180px;"></el-input>
      <el-button type="success" round style="margin-left: 100px;" @click="tijiao_yly()">提交</el-button>

    </div>







  </div>
</template>

<script>
import axios from "axios";

export default {


  data() {


    return {
      businessStatus: false, // 营业状态的值
      shangjia_id: "",

      s_p_id: '', // 输入框1的值
      s_p_key: '', // 输入框2的值
      s_p_name: "",

      yly_appid: "",  // 易联云
      yly_key: "", // 易联云


      zidong_jie: false,

    };




  },




  created() {

    const id = this.$route.query.id; // 获取id参数的值
    console.log(id);
    console.log('iii');
    this.shangjia_id = id


    // https://api.dfwlkeji.com/shangjia/tianjia_sp?id=264&key=1928624052/brzy3dhx/安琪拉



    // console.log("重新加载商家列表");


    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    // console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/shangjia/sousuo", {

        gjc: id



      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑
        console.log(response.data.data[0].vendor_DaYin_S);


        this.s_p_id = response.data.data[0].vendor_DaYin_S.split("/")[0];
        this.s_p_key = response.data.data[0].vendor_DaYin_S.split("/")[1];
        this.s_p_name = response.data.data[0].vendor_DaYin_S.split("/")[2];

        this.yly_appid = response.data.data[0].vendor_DaYin_Y.split("/")[0];
        this.yly_key = response.data.data[0].vendor_DaYin_Y.split("/")[1];

        // 自动接单

        console.log(response.data.data[0].auto_jiedan == 1);

        if (response.data.data[0].auto_jiedan == 1) {

          this.zidong_jie = true


        }





      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);


      });






  },





  methods: {


    //  设置商鹏打印机....
    tijiao_sp() {

      console.log('111');

      let token = localStorage.getItem('token');


      console.log(this.shangjia_id);
      console.log(this.s_p_id);
      console.log(this.s_p_key);
      console.log(this.s_p_name);

      var keykey = this.s_p_id + "/" + this.s_p_key + "/" + this.s_p_name


      axios.get(`https://api.dfwlkeji.com/shangjia/tianjia_sp?id=` + this.shangjia_id + `&key=` + this.s_p_id + `/` + this.s_p_key+ `/` + this.s_p_name, {
        headers: {
          'Authorization': token
        }
      }).then(res => {
        console.log(res);
        alert(res.data.errormsg)
      }).catch(error => {
        console.error(error);
      });





    },


    //  设置易联云
    tijiao_yly() {

      let token = localStorage.getItem('token');
      console.log(this.shangjia_id);

      

      axios.get(`https://api.dfwlkeji.com/shangjia/tianjia_yly?id=` + this.shangjia_id + `&key=` + this.yly_appid + `/` + this.yly_key, {
        headers: {
          'Authorization': token
        }
      }).then(res => {
        console.log(res);
        alert(res.data.message)
      }).catch(error => {
        console.error(error);
      });




    },


    // 设置自动接单的

    auto_jiedan() {

      // this.shangjia_id
      console.log(this.zidong_jie);


      let token = localStorage.getItem('token');


      if (this.zidong_jie) {


        axios.get(`https://api.dfwlkeji.com/shangjia/auto_jiedan?id=` + this.shangjia_id + `&auto=0`, {
          headers: {
            'Authorization': token
          }
        }).then(res => {
          console.log(res);
          if (res.data.message == "修改成功") {

            this.zidong_jie = false


          }

          // alert(res.data.message)
        }).catch(error => {
          console.error(error);
        });



      } else {

        axios.get(`https://api.dfwlkeji.com/shangjia/auto_jiedan?id=` + this.shangjia_id + `&auto=1`, {
          headers: {
            'Authorization': token
          }
        }).then(res => {
          console.log(res);

          if (res.data.message == "修改成功") {

            this.zidong_jie = true


          }
          // alert(res.data.message)
        }).catch(error => {
          console.error(error);
        });


      }

      //  重新加载

      // location.reload()

      // this.jiazai() 


    },













  }

}
</script>

<style></style>