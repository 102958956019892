<template>
  
  <el-container class="shangjiaZuoCe">

    <el-header>
    
      <S_shangjiadingbu />
      
      
    




    </el-header>






    <!-- 左侧加中部 -->
    <el-container >

      <!-- 引入组件 -->
      <RuZhuZuoCe />


      <el-main>
        <!-- 引用路由 -->
        <router-view />
      </el-main>




    </el-container>
  </el-container>

</template>




















<script>

import RuZhuZuoCe from '../business/RuZhuZuoCe.vue';
import S_shangjiadingbu from './S_shangjiadingbu.vue';



export default {

  data() {

    return {

    }

  },
  methods: {


  },
  components: {
    RuZhuZuoCe,
    S_shangjiadingbu
}

}
</script>

<style>




</style>