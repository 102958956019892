import Vue from 'vue';
import VueMeta from 'vue-meta';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

// 设置默认页面标题
Vue.mixin({
  metaInfo: {
    title: '东峰网络科技'
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');