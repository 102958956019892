<template>
    <div>
        <!-- <div style="margin-top: 0px;">
            <h4>搜索位置</h4>
            <input type="text" v-model="cityName" placeholder="输入城市名称" />
            <button type="button" @click="searchCity">搜索</button>
        </div> --> 
        <div id="mapContainer" style="width: 100%; height: 500px;"></div>
    </div>
</template>

<script>
export default {
    name: 'MapComponent',
    data() {
        return {
            cityName: '',
            map: null,
           
        };
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            // 加载地图
            this.map = new AMap.Map('mapContainer', {
                resizeEnable: true,
                // 115.837235/22.812684
                center: [115.837235, 22.812684],
                zoom: 16,
            });

            // 添加点标记
            const marker = new AMap.Marker({
                position: this.map.getCenter(),
                icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                anchor: 'bottom-center',
                offset: new AMap.Pixel(-13, -30),
                draggable: true,
                map: this.map,
            });

            // 拖动获取坐标
            marker.on('dragging', (event) => {
                const position = marker.getPosition();
                const lng = position.getLng();
                const lat = position.getLat();
                const mapData = lng + '/' + lat;
                this.$emit('mapData', mapData);
                console.log(mapData);
            });
        },

        searchCity() {
            if (this.cityName) {

                console.log(this.cityName);

             




                AMap.plugin("AMap.PlaceSearch", function () {
                    var placeSearch = new AMap.PlaceSearch({
                        //city 指定搜索所在城市，支持传入格式有：城市名、citycode 和 adcode
                        city: "010",
                    });
                    placeSearch.search("北京大学", function (status, result) {
                        //查询成功时，result 即对应匹配的 POI 信息
                        console.log(result);
                    });
                });










            }
        },
    },
};
</script>

<style>
html,
body,
#container {
    width: 100%;
    height: 100%;
    margin: 0;
}
</style>