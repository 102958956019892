<template>
  <div>
    <div class="sp_toolbar">

      <el-button type="primary" icon="el-icon-plus" @click="showAddProductDialog = true">添加商品</el-button>

      <el-button type="info" icon="el-icon-upload" @click="importProducts">导入商品</el-button>
      <el-button type="info" @click="deleteSelectedProducts">批量删除</el-button>

      <el-input v-model="searchText" placeholder="搜索商品" class="sp_search-input"
        style="margin-left: 100px; width: 500px;"></el-input>



    </div>




    <el-table :data="filteredProducts" style="width: 100%" :height="1500" max-height="750">
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="SP_id" label="商品ID"></el-table-column>
      <el-table-column prop="SP_name" label="商品名称"></el-table-column>


      <el-table-column prop="image" label="商品图片" width="150">
        <template slot-scope="scope">
          <img :src="scope.row.SP_tupian" alt="" width="60" height="60">
        </template>
      </el-table-column>
      <el-table-column prop="SP_jiage" label="商品价格"></el-table-column>
      <el-table-column prop="SP_kucun" label="商品库存"></el-table-column>
      <el-table-column prop="SP_xiaoliang" label="销量"></el-table-column>
      <el-table-column prop="SP_fenlei" label="所属分类"></el-table-column>
      <el-table-column prop="isOnSale" label="上架状态">
        <template slot-scope="scope">
          <el-switch :value="scope.row.SP_zhuangtai === 1" active-text="上架" inactive-text="下架"
            @change="gg_zhuangtai(scope.row.SP_id, scope.row.SP_zhuangtai)"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="isHot" label="热销推荐">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.isHot"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="dakaibianji(scope.row.SP_id)">编辑</el-button>
          <el-button type="text" @click="shanchushangjia(scope.row.SP_id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>




    <!-- 编辑商品弹窗 -->
    <el-dialog title="请先完成商家基本信息" @click="bianjitanchuang = false" :visible.sync="bianjitanchuang">



      <form @submit.prevent="xiugaishangpin" class="form">


        <div style="display: flex; justify-content: flex-start;">
          <!-- <label for="name"  >商品名称：</label> -->

          <p style="margin-top: 2px;">商品名称：</p>
          <div class="form-group" style="display: flex; align-items: center; margin-right: 20px;">

            <input class="shur" type="text" id="name" v-model="bianji_name" required>

          </div>


          <p style="margin-top: 2px; margin-left: 10px;">单位：</p>

          <div style="display: flex; align-items: center; margin-right: 20px; width: 100px;">

            <div class="form-group" style="margin-right: 20px;">
              <!-- <label for="shouji" style="width: 300px;">单位：</label> -->
              <input class="shur" type="text" id="shouji" v-model="bianji_danwei" required>
            </div>


          </div>





          <el-select v-model="bianji_fenlei" placeholder="请选择分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.Fenlei_name">
            </el-option>
          </el-select>


        </div>




        <div style="display: flex; justify-content: flex-start;">
          <!-- <label for="name"  >商品名称：</label> -->
          <p style="margin-top: 2px;">商品价格：</p>

          <div class="form-group" style="width: 100px;">
            <input class="shur" type="text" id="paixu" v-model="bianji_jiage" required>

          </div>






          <p for="paixu" style="margin-top: 2px; margin-left: 50px;">餐盒费：</p>

          <div class="form-group" style="display: flex; justify-content: flex-start; width: 100px;">
            <input class="shur" type="text" id="paixu" v-model="bianji_canhefei" required>
          </div>


          <p for="paixu" style="margin-top: 2px; margin-left: 50px;">已售：</p>

          <div class="form-group" style="display: flex; justify-content: flex-start; width: 100px;">
            <input class="shur" type="text" id="paixu" v-model="bianji_xiaoliang" required>
          </div>


        </div>



        <div style="display: flex; justify-content: flex-start;">

          <p for="paixu" style="margin-top: 2px; ">商品库存：</p>
          <div class="form-group" style="width: 100px;">
            <!-- <label for="shouji">库存：</label> -->
            <input class="shur" type="text" id="shouji" v-model="bianji_kucun" required>
          </div>

        </div>

        <!-- //   规格设置 -->

        <div style=" margin-bottom: 20px;">


          启用规格： <el-switch :value="guige_zhuangtai" @change="dianjigggg(guige_zhuangtai)"></el-switch>



        </div>


        <div style="margin-bottom: 10px;"> 规格： <el-button type="primary" icon="el-icon-edit"
            @click="tianjia_gg()"></el-button></div>



        <div style="width: 100%; display: flex; flex-wrap: wrap;">


          <div v-for="(shux, index) in guige"
            style="margin-right: 10px; margin-left: 10px; border: 1px solid #007bff; border-right: 1px solid #007bff; border-radius: 10px; padding: 5px; width: 80px;">
            <div style="white-space: nowrap;">{{ shux.SP_NAME }} | {{ shux.SP_JG }}</div>

            <el-button size="mini" round @click="delete_gg(shux.ID)">删除</el-button>

          </div>


        </div>




        <div style="margin-bottom: 10px; margin-top: 15px;"> 属性1：<el-button type="primary" @click="tianjia_shux1()"
            icon="el-icon-edit"></el-button></div>



        <div style="width: 100%; display: flex; flex-wrap: wrap;">


          <div v-for="(shux, index) in shux"
            style="margin-right: 10px; margin-left: 10px; border: 1px solid #007bff; border-right: 1px solid #007bff; border-radius: 10px; padding: 5px; ">
            <div style="white-space: nowrap;">{{ shux.SP_NAME }}</div>
            <el-button size="mini" round @click="delete_shux1(shux.ID)">删除</el-button>
          </div>


        </div>






        <div style="margin-bottom: 10px; margin-top: 15px;"> 属性2：<el-button type="primary" @click="tianjia_shux2()"
            icon="el-icon-edit"></el-button></div>



        <div style="width: 100%; display: flex; flex-wrap: wrap;">


          <div v-for="(shux, index) in shux2"
            style="margin-right: 10px; margin-left: 10px; border: 1px solid #007bff; border-right: 1px solid #007bff; border-radius: 10px; padding: 5px; ">
            <div style="white-space: nowrap;">{{ shux.SP_NAME }}</div>
            <el-button size="mini" round @click="delete_shux2(shux.ID)">删除</el-button>



          </div>


        </div>










        <img style="border: 10px; padding-bottom: 10px;" width="60" height="60" v-if="previewURL" v-bind:src="previewURL"
          alt="Preview Image">


        <div class="file-input">
          <input type="file" id="fileInput" class="input" @change="handleFileChange">
          <label for="fileInput" class="button">上传商品图片</label>
          <span class="file-name">{{ fileName }}</span>
        </div>







        <div style="margin-top: 10px; margin-left: 650px; ">

          <!-- <el-button @click="bianjitanchuang = false" style="margin-left: 50px;" plain>取消</el-button> -->


          <button type="submit" class="submit-button"
            style="margin-top: 100px; width: 80px;  margin-left: 50px; ">提交</button>




        </div>




      </form>






    </el-dialog>









    <!-- 添加弹窗 -->
    <el-dialog title="请先完成商家基本信息" @click="showAddProductDialog = false" :visible.sync="showAddProductDialog">



      <form @submit.prevent="tianjiashangpin" class="form">




        <div style="display: flex; justify-content: flex-start;">
          <!-- <label for="name"  >商品名称：</label> -->

          <p style="margin-top: 2px;">商品名称：</p>
          <div class="form-group" style="display: flex; align-items: center; margin-right: 20px;">

            <input class="shur" type="text" id="name" v-model="name" required>

          </div>


          <p style="margin-top: 2px; margin-left: 10px;">单位：</p>

          <div style="display: flex; align-items: center; margin-right: 20px; width: 100px;">
            <div class="form-group" style="margin-right: 20px;">
              <!-- <label for="shouji" style="width: 300px;">单位：</label> -->
              <input class="shur" type="text" id="shouji" v-model="danwei" required>
            </div>


          </div>














          <el-select v-model="fenleizhi" placeholder="请选择分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.Fenlei_name">
            </el-option>
          </el-select>





        </div>

        <div style="display: flex; justify-content: flex-start;">
          <!-- <label for="name"  >商品名称：</label> -->
          <p style="margin-top: 2px;">商品价格：</p>

          <div class="form-group" style="width: 100px;">


            <input class="shur" type="text" id="paixu" v-model="jiage" required>

          </div>






          <p for="paixu" style="margin-top: 2px; margin-left: 50px;">餐盒费：</p>

          <div class="form-group" style="display: flex; justify-content: flex-start; width: 100px;">
            <input class="shur" type="text" id="paixu" v-model="canhefei" required>
          </div>




        </div>



        <div style="display: flex; justify-content: flex-start;">


          <p for="paixu" style="margin-top: 2px; ">商品库存：</p>

          <div class="form-group" style="width: 100px;">
            <!-- <label for="shouji">库存：</label> -->
            <input class="shur" type="text" id="shouji" v-model="kucun" required>
          </div>







        </div>





        <img style="border: 10px; padding-bottom: 10px;" width="60" height="60" v-if="previewURL" v-bind:src="previewURL"
          alt="Preview Image">


        <div class="file-input">
          <input type="file" id="fileInput" class="input" @change="handleFileChange">
          <label for="fileInput" class="button">上传商品图片</label>
          <span class="file-name">{{ fileName }}</span>
        </div>







        <div style="margin-top: 10px; margin-left: 650px; ">

          <!-- <button     @click="showAddProductDialog = false" style="margin-left: 50px; background: #070ac4; color: white; width: 50px;"  plain>取消</button> -->

          <button type="submit" class="submit-button"
            style="margin-top: 100px; width: 80px;  margin-left: 50px; ">提交</button>




        </div>




      </form>




    </el-dialog>




    <!-- //规格弹窗 -->


    <div v-if="guige_tanc"
      style="width: 100%; height: 100%;  background-color: rgba(100, 100, 100, 0.5); position: fixed; top: 0%; z-index: 9999;"
      @click="guige_tanc = false">

    </div>



    <div v-if="guige_tanc"
      style=" left: 25%; width: 40%; border-right: 15px; padding: 10px; height: 300px; background: white; position: fixed; top: 20%; z-index: 999999999;">

      <div style="margin-bottom: 20px;  font-size: 20px; font-weight: bolder;">请添加规格</div>
      <div>
        <p style="margin-top: 2px;">规格名称：</p>
        <div class="form-group" style="display: flex; align-items: center; margin-right: 20px; z-index: 999999999;">

          <input class="shur" style="width: 80px; " type="text" id="name" v-model="gg_name" required>

        </div>
      </div>
      <div>
        <p style="margin-top: 2px;">价格：</p>
        <div class="form-group" style="display: flex; align-items: center; margin-right: 20px; z-index: 99999999999;">

          <input style="width: 80px;" class="shur" type="text" id="name" v-model="gg_jiage" required>

        </div>
      </div>

      <div style="position: absolute; right: 30px;">

        <el-button type="info" @click="guige_tanc = false">取消</el-button>


        <el-button type="primary" @click="quedintianjia_gg()">确定</el-button>



      </div>





    </div>






    <!-- /-------------------------属性1-->

    <!-- //属性1弹窗 -->


    <div v-if="shux1_tanc"
      style="width: 100%; height: 100%;  background-color: rgba(100, 100, 100, 0.5); position: fixed; top: 0%; z-index: 9999;"
      @click="shux1_tanc = false">

    </div>



    <div v-if="shux1_tanc"
      style=" left: 25%; width: 40%; border-right: 15px; padding: 10px; height: 300px; background: white; position: fixed; top: 20%; z-index: 999999999;">

      <div style="margin-bottom: 20px;  font-size: 20px; font-weight: bolder;">请添属性1</div>
      <div>
        <p style="margin-top: 2px;">属性名称：</p>
        <div class="form-group" style="display: flex; align-items: center; margin-right: 20px; z-index: 999999999;">

          <input class="shur" style="width: 80px; " type="text" id="name" v-model="shux1_name" required>

        </div>
      </div>


      <div style="position: absolute; right: 30px;">

        <el-button type="info" @click="shux1_tanc = false">取消</el-button>


        <el-button type="primary" @click="quedintianjia_shux1()">确定</el-button>



      </div>





    </div>




    <!-- //属性2弹窗 -->


    <div v-if="shux2_tanc"
      style="width: 100%; height: 100%;  background-color: rgba(100, 100, 100, 0.5); position: fixed; top: 0%; z-index: 9999;"
      @click="shux2_tanc = false">

    </div>



    <div v-if="shux2_tanc"
      style=" left: 25%; width: 40%; border-right: 15px; padding: 10px; height: 300px; background: white; position: fixed; top: 20%; z-index: 999999999;">

      <div style="margin-bottom: 20px;  font-size: 20px; font-weight: bolder;">请添属性2</div>
      <div>
        <p style="margin-top: 2px;">属性名称：</p>
        <div class="form-group" style="display: flex; align-items: center; margin-right: 20px; z-index: 999999999;">

          <input class="shur" style="width: 80px; " type="text" id="name" v-model="shux1_name" required>

        </div>
      </div>


      <div style="position: absolute; right: 30px;">

        <el-button type="info" @click="shux2_tanc = false">取消</el-button>


        <el-button type="primary" @click="quedintianjia_shux2()">确定</el-button>



      </div>





    </div>















  </div>
</template>

<script>

import axios from "axios";


export default {
  data() {
    return {

      //编辑的

      bianji_name: '',
      bianji_jiage: '',
      bianji_kucun: '',
      bianji_fenlei: '',
      bianji_danwei: '',
      bianji_xiaoliang: '',
      bianji_canhefei: '',

      guige: [],
      shux: [],
      shux2: [],

      guige_zhuangtai: false, // 启用规格


      guige_tanc: false,//规格弹窗//

      shux1_tanc: false,//规格弹窗//
      shux2_tanc: false,//规格弹窗//








      zhidin_zp_id: '',



      options: [], // 分类列表

      fenleizhi: '',//  分类值

      products: '', //商品数据

      showAddProductDialog: false, //弹窗
      bianjitanchuang: false, //编辑商品弹窗


      selectAll: false,
      searchText: '',
      selectedProducts: [],
      addProductDialogVisible: false,

      previewURL: '',//图片

      imageFile: '',




    };
  },


  computed: {
    filteredProducts() {
      if (this.searchText) {
        return this.products.filter(product =>
          product.SP_name.toLowerCase().includes(this.searchText.toLowerCase())



        );
      } else {
        return this.products;
      }
    }
  },





  created() {


    const id = this.$route.query.id; // 获取id参数的值
    console.log(id);

    // 获取分类列表


    this.shangjia_Id = id

    let token = localStorage.getItem('token');


    console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/fenlei/chaxun", {

        sjid: this.shangjia_Id


      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑


        // console.log("我是列表的");

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });



        }

        console.log(response);


        //  从大到小排列
        response.data.data.sort(function (a, b) {
          return b.Fenlei_xuhao - a.Fenlei_xuhao;
        });


        this.options = response.data.data;


        // console.log(arr);












      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);
      });



































    // let token = localStorage.getItem('token');



    // 在组件创建时执行
    console.log("获取商品列表");


    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/shangpin/chaxun", {

        sjid: this.$route.query.id


      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑


        // console.log("我是列表的");

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });



        }

        this.products = response.data.data;

        console.log(response);

      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);
      });
  },








  methods: {



    delete_shux2(id) {

      console.log(id);




      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,


      axios.get('https://api.dfwlkeji.com/shangpin/delete_shux2?id=' + id, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.sp_guige(this.zhidin_zp_id)


          // this.shux2_tanc = false




        })
        .catch((error) => {
          console.error(error);
        });



















    },



    delete_shux1(id) {

      console.log(id);




      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,


      axios.get('https://api.dfwlkeji.com/shangpin/delete_shux1?id=' + id, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.sp_guige(this.zhidin_zp_id)


          // this.shux2_tanc = false




        })
        .catch((error) => {
          console.error(error);
        });



















    },


    //  点击数删除规格

    delete_gg(id) {

      console.log(id);




      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,


      axios.get('https://api.dfwlkeji.com/shangpin/delete_gg?id=' + id, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.sp_guige(this.zhidin_zp_id)


          // this.shux2_tanc = false




        })
        .catch((error) => {
          console.error(error);
        });



















    },

    //  点击启用规格

    dianjigggg(zt) {

      console.log(zt);

      var id = this.zhidin_zp_id

      console.log(id);

      let token = localStorage.getItem('token');




      if (zt) {


        axios.get('https://api.dfwlkeji.com/shangpin/sp_qiyong_gg?gg=' + 0 + '&id=' + id, {
          headers: {
            'Authorization': token // 将token作为Bearer token放入Authorization头部
          }
        })
          .then((res) => {
            console.log(res.data.data);

            this.guige_zhuangtai = false




          })



      } else {


        axios.get('https://api.dfwlkeji.com/shangpin/sp_qiyong_gg?gg=' + 1 + '&id=' + id, {
          headers: {
            'Authorization': token // 将token作为Bearer token放入Authorization头部
          }
        })
          .then((res) => {
            console.log(res.data.data);
            this.guige_zhuangtai = true





          })



      }










    },

    // 确定添加属性2
    quedintianjia_shux2() {

      console.log(this.zhidin_zp_id);

      var id = this.zhidin_zp_id

      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,


      axios.get('https://api.dfwlkeji.com/shangpin/tianjia_shux2?spid=' + this.zhidin_zp_id + '&name=' + this.shux1_name, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.sp_guige(id)


          this.shux2_tanc = false




        })
        .catch((error) => {
          console.error(error);
        });



    },


    // 添加属性2
    tianjia_shux2() {


      this.shux2_tanc = true

      console.log(this.zhidin_zp_id);

      var id = this.zhidin_zp_id



    },


    // 确定添加属性1
    quedintianjia_shux1() {

      console.log(this.zhidin_zp_id);

      var id = this.zhidin_zp_id

      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,


      axios.get('https://api.dfwlkeji.com/shangpin/tianjia_shux1?spid=' + this.zhidin_zp_id + '&name=' + this.shux1_name, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.sp_guige(id)


          this.shux1_tanc = false




        })
        .catch((error) => {
          console.error(error);
        });



    },



    //  确定添加规格

    quedintianjia_gg() {



      console.log(this.zhidin_zp_id);

      var id = this.zhidin_zp_id

      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,


      axios.get('https://api.dfwlkeji.com/shangpin/tianjia_gg?spid=' + id + '&jiage=' + this.gg_jiage + '&name=' + this.gg_name, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.sp_guige(id)


          this.guige_tanc = false




        })
        .catch((error) => {
          console.error(error);
        });










    },


    // 添加属性1
    tianjia_shux1() {


      this.shux1_tanc = true

      console.log(this.zhidin_zp_id);

      var id = this.zhidin_zp_id



    },
    // 添加规格
    tianjia_gg() {

      this.guige_tanc = true

      console.log(this.zhidin_zp_id);

      var id = this.zhidin_zp_id

      let token = localStorage.getItem('token');

      // SP_ID:req.query.spid,
      // SP_JG:req.query.jiage,
      //   SP_NAME:req.query.name,






    },

    // 获取商品规格

    sp_guige(id) {

      let token = localStorage.getItem('token');

      axios.get('https://api.dfwlkeji.com/shangpin/sp_sp_gg?id=' + id, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.guige = res.data.data

        })
        .catch((error) => {
          console.error(error);
        });




      // info_sp_sx



      axios.get('https://api.dfwlkeji.com/shangpin/info_sp_sx?id=' + id, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.shux = res.data.data

        })
        .catch((error) => {
          console.error(error);
        });



      axios.get('https://api.dfwlkeji.com/shangpin/info_sp_sx2?id=' + id, {
        headers: {
          'Authorization': token // 将token作为Bearer token放入Authorization头部
        }
      })
        .then((res) => {
          console.log(res.data.data);

          this.shux2 = res.data.data

        })
        .catch((error) => {
          console.error(error);
        });











    },



    //打开弹窗编辑修改商品

    dakaibianji(id) {

      console.log(id);

      // 指定商品id

      this.sp_guige(id)

      this.zhidin_zp_id = id


      this.bianjitanchuang = true //打开显示弹窗

      // //通过id在数组中找信息

      for (let index = 0; index < this.products.length; index++) {

        if (this.products[index].SP_id == id) {

          console.log(this.products[index].SP_name);

          let duibiao = this.products[index]

          this.bianji_name = duibiao.SP_name
          this.bianji_jiage = duibiao.SP_jiage
          this.bianji_kucun = duibiao.SP_kucun
          this.bianji_fenlei = duibiao.SP_fenlei

          this.bianji_danwei = duibiao.SP_danwei
          this.bianji_canhefei = duibiao.SP_canhefei

          this.bianji_xiaoliang = duibiao.SP_xiaoliang

          console.log('-----------------------------');

          console.log(duibiao.SP_guige);

          console.log('-----------------------------');



          // if (duibiao.SP_guige == 1) {

          //   console.log(duibiao.SP_guige);

          //   this.guige_zhuangtai = true



          // } else {

          //   this.guige_zhuangtai = false

          // }



          break



        }



      }









    },



    //提交商品资料//修改的 //提交按钮


    xiugaishangpin() {

      console.log(this.zhidin_zp_id);
      console.log('修改商品');


      let token = localStorage.getItem('token');


      var formData = new FormData();
      formData.append('file', this.imageFile); // 替换yourFile为实际的文件对象

      formData.append('name', this.bianji_name);
      formData.append('jiage', this.bianji_jiage);
      formData.append('danwei', this.bianji_danwei);
      formData.append('kucun', this.bianji_kucun);
      formData.append('xiaoliang', this.bianji_xiaoliang);
      formData.append('fenlei', this.bianji_fenlei);
      formData.append('canhefei', this.bianji_canhefei); // 提交时间
      formData.append('id', this.zhidin_zp_id); // 提交时间



      axios.post('https://api.dfwlkeji.com/shangpin/xiugai', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      })
        .then(response => {

          // 请求成功后的处理逻辑

          console.log('请求成功');


          this.$message.success('保存成功');

          // 刷新当前页面
          location.reload();


        })

        .catch(error => {
          // 请求失败后的处理逻辑
        });







      // console.log();












    },

































    //  确定删除商品

    deleteProduct(id) {

      console.log(id);




      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangpin/shanchu", {

          id: id,


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          this.jiazaishangpinbiao()

          setTimeout(function () {
            location.reload();
          }, 500);


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });




    },





    //删除商品
    shanchushangjia(id) {


      //   // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('删除商家id:', id);

      console.log(this.products);

      var sjmc = null
      //  提取名称
      for (var i = 0; i < this.products.length; i++) {
        console.log(this.products[i].SP_id
        );

        if (this.products[i].SP_id == id) {

          sjmc = this.products[i].SP_name

          break


        }
      }



      this.$confirm('确定删除该 商品吗？ 《 ' + sjmc + " 》 ", ' 提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.deleteProduct(id) // 删除商家



        this.$message({

          type: 'success',
          message: '删除成功!'

        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },



    //  更改状态

    gg_zhuangtai(id, zhuangtaizhi) {

      //  这里写请求接口 修改状态的接口
      console.log("nih");
      console.log("当前商家id", id);
      console.log("当前商家营业状态", zhuangtaizhi);

      var shezhi

      if (zhuangtaizhi === 0) {

        shezhi = 1

      } else {

        shezhi = 0
      }

      // 商家的id  //shezhi

      console.log("需要改成：", shezhi);


      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangpin/zhuangtai", {

          id: id,
          zhuangtai: shezhi



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.products = response.data.data;



          this.jiazaishangpinbiao()


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });





















    },






    // 上传logo

    handleFileChange(event) {

      this.imageFile = event.target.files[0];
      const file = event.target.files[0];

      console.log(this.imageFile);


      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewURL = e.target.result;
      };

      reader.readAsDataURL(file);


    },


    // // 添加商品


    tianjiashangpin() {

      let token = localStorage.getItem('token');


      // sjid: req.body.sjid,
      //   SP_name: req.body.name,
      //   SP_jiage: req.body.jiage,
      //   SP_danwei: req.body.danwei,
      //   SP_kucun: req.body.kucun,
      //   SP_fenlei: req.body.fenlei,

      console.log(this.fenleizhi);





      var formData = new FormData();

      formData.append('file', this.imageFile); // 替换yourFile为实际的文件对象
      formData.append('sjid', this.$route.query.id);
      formData.append('kucun', this.kucun);
      formData.append('name', this.name);
      formData.append('jiage', this.jiage);
      formData.append('danwei', this.danwei);
      formData.append('fenlei', this.fenleizhi);
      formData.append('canhefei', this.canhefei);





      axios.post('https://api.dfwlkeji.com/shangpin/tianjia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      })
        .then(response => {

          // 请求成功后的处理逻辑



          console.log(response.data.insertedId);


          this.dialogFormVisible = false  // 关闭弹窗

          //  location.reload();



          this.$message.success('添加成功');



          setTimeout(function () {
            location.reload();
          }, 500);




        })

        .catch(error => {
          // 请求失败后的处理逻辑
        });





    },





    //  加载商品列表

    jiazaishangpinbiao() {



      let token = localStorage.getItem('token');



      // 在组件创建时执行
      console.log("获取商品列表");


      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangpin/chaxun", {

          sjid: this.$route.query.id


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          this.products = response.data.data;

          console.log(response);

        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });



    },







    searchProducts() {
      // 搜索商品逻辑
      console.log('搜索商品:', this.searchText);
    },





    addNewProduct() {


      console.log(this.products.SP_name);


      // 关闭对话框
      this.addProductDialogVisible = false;



    },


    importProducts() {
      // 导入商品逻辑
      console.log('导入商品');
    },
    deleteSelectedProducts() {
      // 批量删除商品逻辑
      console.log('批量删除商品:', this.selectedProducts);
    },
    addSpecification() {
      // 添加规格
      this.newProduct.specifications.push({
        name: '',
        price: 0,
        stock: 0
      });
    },
    removeSpecification(index) {
      // 移除规格
      this.newProduct.specifications.splice(index, 1);
    },
    addAttribute() {
      // 添加属性
      this.newProduct.attributes.push({
        name: '',
        value: ''
      });
    },
    removeAttribute(index) {
      // 移除属性
      this.newProduct.attributes.splice(index, 1);
    },
    handleUploadSuccess(response, file, fileList) {
      // 上传成功回调
      if (response && response.url) {
        this.newProduct.image = response.url;
      }
    },


    beforeUpload(file) {
      // 上传前的钩子函数
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error('只支持上传 JPG 或 PNG 格式的图片');
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB');
      }

      return isJPG || isPNG && isLt2M;
    }
  }
};
</script>

<style>
form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  margin-right: 10px;
}

.shur {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.file-input {
  margin-top: 20px;
  position: relative;
}

.input {
  display: none;
}

.button {
  background-color: #007bff;
  color: #fff;
  padding: 8px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.file-name {
  margin-left: 10px;
}


.submit-button:hover {
  background-color: #218838;
}

.cancel-button {
  margin-top: 20px;
  color: #007bff;
  padding: 10px 20px;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #f8f9fa;
}

.preview-image {
  border: 10px solid #f8f9fa;
  margin-top: 20px;
  border-radius: 4px;
}
</style>