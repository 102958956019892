<template>
  <div class="custom-div">
    <el-container>

      <!-- 概括内容 -->
      <el-header>
        <h4>外卖订单概括</h4>
        <el-table :data="tableData" class="custom-table" :row-class-name="tableRowClassName">
          <el-table-column prop="date" label="待接单" width="350">
          </el-table-column>
          <el-table-column prop="name" label="待配送" width="350">
          </el-table-column>
          <el-table-column prop="name" label="有催单" width="350">
          </el-table-column>
        </el-table>
      </el-header>

      <!-- ------------ -->

      <el-header class="custom-main">
        <h4>商家概括</h4>
        <el-table :data="tableData" class="custom-table" :row-class-name="tableRowClassName">
          <el-table-column prop="date" label="营业中" width="350">
          </el-table-column>
          <el-table-column prop="name" label="打烊中" width="350">
          </el-table-column>
          <el-table-column prop="name" label="所有商家" width="350">
          </el-table-column>
        </el-table>
      </el-header>



      <!-- --------------------- -->
      <el-header class="custom-main">
        <h4>配送员概括</h4>
        <el-table :data="tableData" class="custom-table" :row-class-name="tableRowClassName">
          <el-table-column prop="date" label="接单中" width="350">
          </el-table-column>
          <el-table-column prop="name" label="休息中" width="350">
          </el-table-column>
          <el-table-column prop="name" label="所有配送员" width="350">
          </el-table-column>
        </el-table>
      </el-header>



      <!-- ----------------------- -->

    </el-container>




  </div>
</template>






<script>

import axios from "axios";

export default {

  data() {
    return {

      yonghu: ""

    }
  },


  created() {

    console.log("开始验证");


    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .get("https://api.dfwlkeji.com/my/userinfo", { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑




        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          
          this.$router.push({ path: "../Loin" });



        }


        this.yonghu = response.data.data;


      })
      .catch((error) => {
        // 请求失败处理逻辑

        console.error(error);
      });






  }


}
</script>

<style>
.custom-table {
  width: 100%;
  font-size: 20px;
  /* 调整字体大小 */
}

.custom-table th {
  font-size: 18px;
  /* 调整表头字体大小 */
}




/* ///商户概括 */
.custom-main {
  margin-top: 150px;
  /* 调整上边距，向下移动表格 */
}


.custom-div {
  margin-left: 180px;
  /* 调整左边距，向右移动整个 div */
}
</style>