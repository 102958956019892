<template>
    <p>售后页面</p>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  
  </style>