<!-- ///左侧导航栏 -->
<template>
  <div class="ZuoCe">


    <el-aside class="aside" width="185px">
      <el-col :span="24">
        <h4 class="dfxt">
          <img src="https://api.dfwlkeji.com/266/266_log.jpg" alt="" class="logo">
          东峰外卖系统
        </h4>
        <el-menu default-active="/ZhuYe/GaiKuo" class="el-menu-vertical-demo" background-color="#304156"
          text-color="#fff" active-text-color="#409EFF" router>

          <!-- //导航栏表 -->

          <el-menu-item index="/ZhuYe/GaiKuo">
            <i class="el-icon-money"></i>
            <span slot="title">平台概括</span>
          </el-menu-item>


          <el-menu-item index="/zhuye/DingDan">
            <i class="el-icon-s-order"></i>
            <span slot="title">订单状态</span>
          </el-menu-item>




          <el-menu-item index="/zhuye/ShuJu">
            <i class="el-icon-coin
                                     "></i>
            <span slot="title">运营数据</span>
          </el-menu-item>



          <el-menu-item index="/zhuye/LieBiao" @click="handleButtonClick">
            <i class="el-icon-s-shop"></i>
            <span slot="title">商户列表</span>
          </el-menu-item>


          <el-menu-item index="/zhuye/DianYuan">
            <i class="el-icon-user-solid"></i>
            <span slot="title">店员管理</span>
          </el-menu-item>

          <el-menu-item index="/zhuye/PeiSongYuan">
            <i class="el-icon-bicycle"></i>
            <span slot="title">配送员</span>
          </el-menu-item>
          <el-menu-item index="/zhuye/GuKeBiao">
            <i class="el-icon-s-custom"></i>
            <span slot="title">顾客列表</span>
          </el-menu-item>
          <el-menu-item index="/zhuye/ShouHou">
            <i class="el-icon-chat-dot-round"></i>
            <span slot="title">售后评价</span>
          </el-menu-item>


          <el-menu-item index="/zhuye/YingYong">
            <i class="el-icon-menu"></i>
            <span slot="title">应用工具</span>
          </el-menu-item>



          <el-submenu>
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>系统设置</span>
            </template>
            <el-menu-item-group>
              <!-- <template slot="title">请谨慎设置</template> -->
              <el-menu-item index="/zhuye/shezhi_jichu">基础设置</el-menu-item>
              <el-menu-item index="1-2">选项2</el-menu-item>
            </el-menu-item-group>

          </el-submenu>




        </el-menu>
      </el-col>


    </el-aside>


  </div>
</template>





  
<script>

import axios from "axios";

export default {

  data() {
    return {
      refreshKey: 0,
      isActive: false
    };
  },

  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.isActive ? 'darken(20%)' : 'initial'
      };
    }
  },

  methods: {


    handleButtonClick() {
      // this.refreshKey++; // 更新refreshKey的值

      console.log("111");

      // this.$router.replace({ path: "../zhuye/LieBiao" });
      // $router.replace('/zhuye/LieBiao');





    },






  }




}
</script>
  
<style>
.aside {
  width: 205px;
  height: 919px;


}




body {
  margin: 0;
  padding: 0;
}







.ZuoCe {
  display: inline-block;
  height: 950px;
  background-color: #304156;
  width: 188px;
  transform: translate(-56%, 0%);


}


.dfxt {
  display: flex;
  /* 使用弹性布局 */
  align-items: center;
  /* 垂直居中对齐 */
  font-weight: bold;
  /* 设置字体加粗 */
  margin-left: 14px;
  /* 向右移动10像素 */
  color: #ffffff;
  /* 设置字体颜色为白色 */

}

.logo {
  width: 40px;
  /* 设置Logo图片的宽度 */
  height: 40px;
  /* 设置Logo图片的高度 */
  margin-right: 10px;
  /* 设置Logo图片的右边距 */
}
</style>