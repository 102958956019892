
// console.log(token);

import axios from "axios";

export const qq_jied = function (id) {

    let token = localStorage.getItem('token');

    console.log(1111);



    var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
    };

    var res = axios.post("https://api.dfwlkeji.com/dindang/shangjiajiedan_shangjia", {

        id: id


    }, { headers: headers })


    return res

}




export const quxiao_dindan = function (id) {

    let token = localStorage.getItem('token');

    console.log(1111);



    var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
    };

    var res = axios.post("https://api.dfwlkeji.com/dindang/diandan_quxiao", {

        id: id


    }, { headers: headers })


    return res

}






//  完成订单
export const wancheng_dd = function (id) {

    let token = localStorage.getItem('token');

    // console.log(1111);



    var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
    };

    axios.post("https://api.dfwlkeji.com/shijian_wanc?id=245", {

        id: id


    }, { headers: headers })


    return headers

}


//  加载所有订单 https://api.dfwlkeji.com/dindang/chaxun


//  加载所有订单
export const jiazai_dd = function () {

    let token = localStorage.getItem('token');

    // console.log(1111);



    var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
    };

    var jiazai = axios.post("https://api.dfwlkeji.com/dindang/chaxun", {



    }, { headers: headers })


    return jiazai

}




//  调度通知  发送订阅消息

export const dingyue_diaodu = function (openid,ddbh,shijain,shangjianname,qishouxinxi) {

    // let token = localStorage.getItem('token');

    console.log('开始请求...');

    // var headers = {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     Authorization: token,
    //     Accept: "*/*",
    //     "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
    //     Host: "kaifa.beilakeji.top",
    //     "Accept-Encoding": "gzip, deflate, br",
    //     Connection: "keep-alive",
    // };

    var id='owm9y6z3wJTrx0Sf_vl94GyYbKo0'

    var jiazai = axios.get("https://api.dfwlkeji.com/api/acc_tiaodu", {

        openid: id.toString(),
        ztnr: '骑手已取货',
        ddbh: 'A16548562',
        shijain: '2019-12-08 15:42',
        shangjianname: '安琪拉饮品店',
        qishouxinxi: '陈建链-1369295654',

    })


    return jiazai

}

