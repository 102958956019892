<template>
  <P>
    评价页面
  </P>
</template>

<script>
export default {

}
</script>

<style>

</style>