<template>
  <div>
    <!-- 搜索框 -->

    <el-container>
      <el-header>

        <div class="container">


          <!-- /////添加商家 -->



          <!-- <el-button type="primary" icon="el-icon-plus" @click="openQitePage">添加商户</el-button> -->

          <el-button type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">添加商户</el-button>


          <!-- 弹窗 -->
          <el-dialog title="请先完成商家基本信息" @click="dialogFormVisible = false" :visible.sync="dialogFormVisible">





            <div style="display: flex;  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50vh; /* 设置容器高度为视口高度，使表单垂直居中 */">



              <form @submit.prevent="handleSubmit" class="form" style="width: 100%;">

                <div style="width: 100%;">


                  <div class="form-group">
                    <label for="name">店铺名称：</label>
                    <input class="shur" style="width: 150px;" type="text" id="name" v-model="name" required>
                  </div>


                  <div class="form-group">
                    <label for="paixu">店铺排序：</label>
                    <input class="shur" style="width: 50px;" type="text" id="paixu" v-model="paixu" required>
                  </div>


                  <div class="form-group">
                    <label for="shouji">商家手机：</label>
                    <input class="shur" style="width: 120px;" type="Number" id="shouji" v-model="shouji" required>
                  </div>
                </div>

                <div style="width: 100%;">


                  <div class="form-group" style="float: left;">
                    <label for="shouji"> 支付账号：</label>
                    <input class="shur" style="width: 80px;" type="text" id="txzhanghao" v-model="txzhanghao" required>
                  </div>

                  <div class="form-group" style="float: left;">
                    <label style="margin-left: 10px;" for="shouji"> 姓名：</label>
                    <input class="shur" style="width: 60px;" type="text" id="txname" v-model="txname" required>
                  </div>


                  <div class="form-group">
                    <label style="margin-left: 10px;" for="shouji"> 点数：</label>
                    <input class="shur" style="width: 30px;" type="Number" id="dianshu" v-model="dianshu" required>
                    <label for="shouji">%</label>

                  </div>


                  <div class="form-group">
                    <label style="margin-left: 10px;" for="shouji"> 起送价：</label>
                    <input class="shur" style="width: 50px;" type="Number" id="dianshu" v-model="qisongjia" required>
     

                  </div>





                </div>





                <div style="width: 100%;">



                  <img style="border: 10px; " width="60" height="60" v-if="previewURL" v-bind:src="previewURL"
                    alt="Preview Image">



                  <div class="file-input" style="float: left; ">
                    <input type="file" id="fileInput" class="input" @change="handleFileChange">
                    <label for="fileInput" class="button">选择logo</label>
                    <span class="file-name">{{ fileName }}</span>
                  </div>




                  <div class="form-group" style="float:left; ">
                    <label for="category1">主营类目</label>
                    <select class="shur" id="category1" v-model="category1" required>
                      <option value="">请选择</option>
                      <option value="饮品甜品">饮品甜品</option>
                      <option value="麻辣烫">麻辣烫</option>
                      <option value="火锅炖品">火锅炖品</option>
                      <option value="早餐包点">早餐包点</option>
                      <option value="汉堡披萨">汉堡披萨</option>
                      <option value="炒饭炒粉">炒饭炒粉</option>
                      <option value="云吞饺汤">云吞饺汤</option>
                      <option value="本地小吃">本地小吃</option>
                      <option value="烧烤炸串">烧烤炸串</option>
                      <option value="超市便利">超市便利</option>


                    </select>


                  </div>


                  <div class="form-group" style="float:left; ">
                    <label for="category2">辅营类目</label>
                    <select class="shur" id="category2" v-model="category2" required>
                      <option value="">请选择</option>
                      <option value="其他">其他</option>
                      <option value="饮品甜品">饮品甜品</option>
                      <option value="麻辣烫">麻辣烫</option>
                      <option value="火锅炖品">火锅炖品</option>
                      <option value="早餐包点">早餐包点</option>
                      <option value="汉堡披萨">汉堡披萨</option>
                      <option value="炒饭炒粉">炒饭炒粉</option>
                      <option value="云吞饺汤">云吞饺汤</option>
                      <option value="本地小吃">本地小吃</option>
                      <option value="烧烤炸串">烧烤炸串</option>
                      <option value="超市便利">超市便利</option>
                    </select>
                  </div>


                </div>



                <button type="submit" class="submit-button" style="width: 100%; margin-top: 30px;">提交</button>

              </form>

            </div>

            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <!-- <el-button type="primary" @click="navigateToNextPage">确 定</el-button> -->
            </div>



          </el-dialog>






          <!-- 搜索商家 -->

          <div style="margin-top: -40px; padding-left: 350px;">

            <!-- <el-input v-model="sousuo.ssgjc" autocomplete="off" style="width: 300px; " placeholder="输入商家名称/ID"></el-input> -->

            <el-input v-model="sousuo.ssgjc" autocomplete="off" :style="{ width: '220px', height: '40px' }"
              placeholder="请输入店铺名称/ID"></el-input>

            <el-button type="primary" style="margin-left: 20px;" icon="el-icon-search"
              @click="dianjisousuo(sousuo.ssgjc)"></el-button>

            <el-button type="success" style="margin-top: 0px; margin-left: 160px;" @click="quanbushangjia"
              plain>全部商家</el-button>

            <!-- <el-button type="primary">主要按钮</el-button> -->

            <el-button type="info" style="margin-top: 0px; margin-left: 25px;" @click="xiuxizhongde"
              plain>休息中的商家</el-button>
            <el-button type="info" style="margin-top: 0px; margin-left: 25px;" @click="beiyincangde"
              plain>被隐藏的商家</el-button>



          </div>



        </div>
      </el-header>

      <el-main>
        <!-- /////表格 -->

        <div>
          <!-- <el-table :data="shangjia" style="width: 100%;" fixed="left" > -->

          <el-table :data="shangjia" style="width: 100%" max-height="670">

            <el-table-column fixed prop="vendor_ID" label="商家ID" width="80">
            </el-table-column>



            <el-table-column prop="vendor_LOGO" label="店铺LOGO" width="150">
              <template slot-scope="scope">
                <img :src="scope.row.vendor_LOGO" alt="" width="50" height="50">
              </template>
            </el-table-column>




            <el-table-column prop="vendor_MC" label="店铺名称" width="220">
            </el-table-column>

            <el-table-column prop="vendor_XiaoLliang" label="销量" width="100">
            </el-table-column>

            <el-table-column prop="vendor_PaiXU" label="排序" width="100">
            </el-table-column>

            <el-table-column prop="vendor_ZhuangTai" label="营业状态" width="180">
              <template slot-scope="scope">
                <el-switch :value="scope.row.vendor_ZhuangTai === 1" active-text="营业" inactive-text="休息"
                  @change="gg_zhuangtai(scope.row.vendor_ID, scope.row.vendor_ZhuangTai)"></el-switch>
              </template>
            </el-table-column>

            <!-- <el-table-column prop="vendor_ZhuangTai" label="显示/隐藏" width="150">

              <el-switch v-model="zhuangtai" active-text="显示" inactive-text="隐藏" @change="xianshi(10)">
              </el-switch>


            </el-table-column> -->

            <el-table-column prop="vendor_YingCang" label="显示/隐藏" width="180">
              <template slot-scope="scope">
                <el-switch :value="scope.row.vendor_YingCang === 1" active-text="显示" inactive-text="隐藏"
                  @change="xianshi(scope.row.vendor_ID, scope.row.vendor_YingCang)"></el-switch>
              </template>
            </el-table-column>




            <el-table-column prop="vendor_ShiJian" label="入住时间" width="250">
            </el-table-column>


            <el-table-column label="操作" width="100">


              <template slot-scope="scope">
                <!-- <input type="button" value="管理" @click="handleManage(scope.row.vendor_ID)"> -->
                <el-button type="primary" icon="el-icon-setting" @click="handleManage(scope.row.vendor_ID)">管理</el-button>
              </template>



            </el-table-column>



            <el-table-column label="操作" width="100">


              <template slot-scope="scope">
                <!-- <input type="button" value="管理" @click="handleManage(scope.row.vendor_ID)"> -->
                <!-- <el-button style="background: #F56C6C; border: 0px;" type="primary" icon="el-icon-delete"
                  @click="shanchushangjia(scope.row.vendor_ID)">删除</el-button> -->
              </template>



            </el-table-column>


          </el-table>
        </div>






      </el-main>





    </el-container>
  </div>
</template>

<script>
import axios from "axios";



export default {
  data() {
    return {
      shangjia: "",
      jieshoushuju: "",
      value: "100",
      zhuangtai: true,
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: '',
        dianhua: '',
        xuhao: '',
        logo: '',
        fenlei: {
          zhu: "",
          fu: ""
        }


      },

      previewURL: '', //logo

      sousuo: {
        ssgjc: ''


      },

      uploadUrl: 'https://example.com/upload', // 替换为你的上传接口URL


      formLabelWidth: '120px'
    };
  },

  /////



  // watch: {
  //   value(newValue) {
  //     // 在开关状态改变时触发
  //     console.log("开关状态改变:", newValue);
  //   },

  // },

  created() {
    // 在组件创建时执行
    console.log("组件创建");

    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/shangjia/chaxun", {}, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑


        // console.log("我是列表的");

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });



        }

        this.shangjia = response.data.data;

      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);
      });
  },


  mounted() {
    // 在组件挂载到DOM后执行
    console.log("组件挂载");
    this.startTimer();
  },


  updated() {
    // 在组件更新后执行
    console.log("组件更新");
  },

  destroyed() {
    // 在组件销毁前执行
    console.log("组件销毁");
    this.stopTimer();
  },

  methods: {





    // 上传logo

    handleFileChange(event) {

      this.imageFile = event.target.files[0];
      const file = event.target.files[0];

      console.log(this.imageFile);


      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewURL = e.target.result;
      };

      reader.readAsDataURL(file);








    },



    //---- 添加商家

    handleSubmit() {

      let token = localStorage.getItem('token');


      const currentTime = new Date(); // 当前时间



      const date = currentTime.toLocaleDateString(); // 获取日期部分
      const time = currentTime.toLocaleTimeString(); // 获取时间部分

      const formattedDateTime = `${date} ${time}`; // 拼接日期和时间

      console.log(formattedDateTime); // 输出格式化后的日期时间



      console.log(formattedDateTime);



      var formData = new FormData();
      formData.append('file', this.imageFile); // 替换yourFile为实际的文件对象
      formData.append('mingcheng', this.name);
      formData.append('paixu', this.paixu);
      formData.append('shouji', this.shouji);
      formData.append('fenlei', this.category1 + "/" + this.category2);
      formData.append('logo', 0);
      formData.append('ruzhushijian', formattedDateTime);
      formData.append('txzhanghao', this.txzhanghao);  // 支付宝
      formData.append('txname', this.txname);  // 姓名
      formData.append('dianshu', this.dianshu);  // 点数
      formData.append('qisongjia', this.qisongjia);  // 点数


      




      axios.post('https://api.dfwlkeji.com/shangjia/tianjia', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      })
        .then(response => {

          // 请求成功后的处理逻辑



          console.log(response.data.insertedId);


          this.dialogFormVisible = false  // 关闭弹窗

          //  location.reload();



          setTimeout(() => {
            window.open("../business/SheZhi?id=" + response.data.insertedId, "_blank");  // 携带id跳转路由
          }, 0);

          // 刷新当前页面
          //  location.reload();



        })

        .catch(error => {
          // 请求失败后的处理逻辑
        });





    },







    // 点击全部商家

    quanbushangjia() {


      this.jiazaishangjialiebiao()

    },




    // 被隐藏的商家

    beiyincangde() {

      console.log('被隐藏的商家');

      let token = localStorage.getItem('token');

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/beiyincangde", {

          // gjc: this.sousuo.ssgjc



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });


    },







    //休息中的商家

    xiuxizhongde() {

      console.log('休息中的...');

      let token = localStorage.getItem('token');

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/xiuxizhongde", {

          // gjc: this.sousuo.ssgjc



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });


    },


    //  点击搜索
    dianjisousuo(gg) {

      // console.log("重新加载商家列表");
      console.log(gg);


      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/sousuo", {

          gjc: this.sousuo.ssgjc



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });
    },




    shanchushangjia(id) {


      //   // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('删除商家id:', id);

      // console.log(this.shangjia);

      var sjmc = null
      //  提取名称
      for (var i = 0; i < this.shangjia.length; i++) {
        console.log(this.shangjia[i].vendor_ID
        );

        if (this.shangjia[i].vendor_ID == id) {

          sjmc = this.shangjia[i].vendor_MC

          break


        }
      }



      this.$confirm('此操作将永久删除该商家! ' + sjmc + "  ", ' 提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.shanchuhanshu(id) // 删除商家



        this.$message({

          type: 'success',
          message: '删除成功!'

        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },




    //  删除的函数
    shanchuhanshu(id) {



      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/shanchushangjia", {

          id: id,


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

          // window.location.reload();
          this.jiazaishangjialiebiao()


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });


    },





    //管理商家
    handleManage(id) {
      // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('Manage button clicked for ID:', id);

      console.log('管理商家id:', id);


      setTimeout(() => {
        window.open("../business/D_ShuJu?id=" + id, "_blank");
      }, 0);


    },





    //  更改显示或隐藏

    xianshi(id, xianshide) {
      //  这里写请求接口 修改状态的接口

      var shezhi

      if (xianshide === 0) {

        shezhi = 1

      } else {

        shezhi = 0
      }

      // 商家的id  //shezhi

      console.log("需要改成：", shezhi); // 显示状态




      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/xianshi", {

          id: id,
          xianshi: shezhi



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

          // window.location.reload();
          this.jiazaishangjialiebiao()


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });












      // window.location.reload();
      // this.jiazaishangjialiebiao()


      // this.shangjia=[{vendor_YingCang:0,vendor_ID:1}]

      // this.zhuangtai = false
      // this.zhuangtai = false;


    },






    //  更改营业状态状态

    gg_zhuangtai(id, zhuangtaizhi) {

      //  这里写请求接口 修改状态的接口
      console.log("nih");
      console.log("当前商家id", id);
      console.log("当前商家营业状态", zhuangtaizhi);

      var shezhi

      if (zhuangtaizhi === 0) {

        shezhi = 1

      } else {

        shezhi = 0
      }

      // 商家的id  //shezhi

      console.log("需要改成：", shezhi);


      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/zhuangtai", {

          id: id,
          zhuangtai: shezhi



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

          // window.location.reload();
          this.jiazaishangjialiebiao()


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });





















    },





    //  搜索的
    sousuo() {
      console.log("chufal");

      console.log(this.jieshoushuju);

      let token = localStorage.getItem('token');


      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
        "User-Agent": "PostmanRuntime/7.35.0",
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/chaxun", {}, { headers: headers })
        .then((response) => {
          // 请求成功处理逻辑
          console.log(response.data);
          this.jieshoushuju = response.data.data;

          console.log(response);


        })
        .catch((error) => {
          // 请求失败处理逻辑

          console.error(error);
        });
    },

    ///添加商户直接打开新的一个页面
    // openQitePage() {
    //   window.open('../zhuye/business/RuZhu', '_blank');
    // },


    //  加载商家列表函数

    jiazaishangjialiebiao() {

      console.log("重新加载商家列表");


      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/shangjia/chaxun", {}, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response.data);

          // console.log("ccgg");

          this.shangjia = response.data.data;
        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });
    }












  },
};
</script>

<style>
.container {
  margin-top: 39px;
}



.moveable-component {
  position: relative;
  top: 100px;
  left: -150px;
}

.moveable {
  top: -103px;
  bottom: 30px;
  transform: translate(150%, 105%);



}

.dianpumingcheng {

  position: relative;
  top: -50px;
  bottom: 50px;
  transform: translate(-15%, 10%);

}

.shangjiadianhua {

  position: relative;
  top: -90px;
  bottom: 50px;
  transform: translate(26%, 10%);

}


.dianshu {

  position: relative;
  top: -50px;
  bottom: 50px;
  transform: translate(26%, 10%);

}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* 调整容器的高度 */
  transform: translate(-40%, 10%);
}

.loading-image {
  width: 100px;
  /* 调整图片的宽度 */
  height: 100px;
  /* 调整图片的高度 */
}

.upload-demo {

  position: relative;
  top: -165px;
  bottom: 50px;
  transform: translate(40%, 10%);



}







.form-container {
  width: 100%;
  /* 设置表单宽度为100% */
  height: 100%;
  /* 设置表单高度为100% */
  overflow: hidden;
  /* 防止表单元素超出弹窗 */
}




/* //biaod */


.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

/* input,
select {

} */


.shur {

  padding: 8px;
  border: 1px solid #2fa9f0;
  border-radius: 5px;
  width: 80%;


}




.submit-button {
  padding: 10px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}



.file-input {
  position: relative;
  display: inline-block;
}

.input {
  position: absolute;
  left: -9999px;
}

.button {
  display: inline-block;
  padding: 8px 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 8px;

}


.button:hover {
  background-color: #0056b3;
  border-radius: 12px;
  color: rgb(247, 246, 250);


}

.file-name {
  margin-left: 6px;
}
</style>
