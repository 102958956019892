<template>
  <div>






    <div>
      <el-button type="primary" @click="openModal">添加店员</el-button>

    </div>


    <!-- // 添加的这是一个弹窗 -->
    <div v-if="showModal"
      style="width: 100%; height: 1200px; background: rgba(32, 34, 32, 0.5); position: fixed; top: 10px; z-index: 99;">

      <div
        style="width: 50%; height: 300px;  background: #fff; z-index: 99999999999; margin-top: 200px; margin: auto 15%; margin-left: 2%;  border-radius: 10px;">
        <div style="margin-left: 3%; margin-top: 5%;  z-index: 999999999999;">
          <br>
          <h2 style="margin-top: 10px;">添加店员</h2>
          手机: <el-input v-model="shouji" placeholder="请输入内容" style="width: 20%;"></el-input>
          密码: <el-input v-model="mima" placeholder="请输入登录密码" style="width: 20%;"></el-input>
          姓名: <el-input v-model="name" placeholder="请输入姓名" style="width: 20%;"></el-input>
          商户ID: <el-input v-model="shid" placeholder="请输入商户ID" style="width: 8%;"></el-input>







        </div>


        <div style="margin-top: 100px;  margin-left: 70%;">

          <el-button type="info" @click="closeModal">取消</el-button>

          <el-button type="primary" @click="shijian_tianjia">提交</el-button>

        </div>




      </div>




    </div>



    <!-- // 修改的的这是一个弹窗 -->
    <div v-if="xiugai_showModal"
      style="width: 100%; height: 1200px; background: rgba(32, 34, 32, 0.5); position: fixed; top: 10px; z-index: 99;">

      <div
        style="width: 50%; height: 400px;  background: #fff; z-index: 99999999999; margin-top: 200px; margin: 10px 15%; border-radius: 10px; margin-left: 2%; ">
        <div style="margin-left: 3%; margin-top: 5%;  z-index: 999999999999;">
          <p></p>
          <br>
          <p style="margin-top: 10px;">修改店员资料___{{ bianji.SH_name }}</p>
          <div style="margin-top: 5px;">
            手机: <el-input v-model="bianji.shouji" placeholder="请输入内容" style="width: 20%;"></el-input>

          </div>
          <div style="margin-top: 5px;">

            密码: <el-input v-model="bianji.mima" placeholder="请输入登录密码" style="width: 20%;"></el-input>
          </div>
          <div style="margin-top: 5px;">

            姓名: <el-input v-model="bianji.name" placeholder="请输入姓名" style="width: 20%;"></el-input>
          </div>

          <div style="margin-top: 5px;">

            商户ID: <el-input v-model="bianji.SH_ID" placeholder="请输入商户ID" style="width: 10%;"></el-input>

          </div>



     



        </div>


        <div style="margin-top: 80px;  margin-left: 70%;">

          <el-button type="info" @click="closeModal">取消</el-button>

          <el-button type="primary" @click="dianji_xiugai(bianji.id)">提交修改</el-button>

        </div>




      </div>




    </div>









    <el-table :data="tableData" height="600" border style="width: 80%; height: 600px;">


      <el-table-column label="id" width="180">
        <template slot-scope="scope">

          <span style="margin-left: 10px">{{ scope.row.id }}</span>
        </template>
      </el-table-column>


      <el-table-column label="姓名" width="180">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <p>姓名: {{ scope.row.name }}</p>

            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">{{ scope.row.name }}</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>


      <el-table-column label="商家ID" width="180">
        <template slot-scope="scope">

          <span style="margin-left: 10px">{{ scope.row.SH_ID }}</span>
        </template>
      </el-table-column>


      <el-table-column label="店铺名称" width="280">
        <template slot-scope="scope">


          <span v-if="scope.row.SH_ID" style="margin-left: 10px; font-weight: bolder;">{{ shangjia_name.filter(item => item.vendor_ID ==
            scope.row.SH_ID)[0].vendor_MC
          }}</span>

        </template>
      </el-table-column>



      <el-table-column label="手机" width="280">
        <template slot-scope="scope">

          <span style="margin-left: 10px">{{ scope.row.shouji }}</span>
        </template>
      </el-table-column>




      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>





  </div>
</template>

<script>

import axios from "axios";

export default {
  data() {
    return {
      tableData: [],
      shangjia_name: [],
      showModal: false,
      shouji: "",
      mima: "",
      shid: "",
      name: "",
      bianji: {},
      xiugai_showModal: false,// 修改的弹窗

    }
  },


  // https://api.dfwlkeji.com/dianyuanguangli/dy_info //  加载店员


  created() {

    this.jaizaishuju()
    console.log('111111111111111');

    console.log(this.tableData.length);



  },


  methods: {


    jaizaishuju() {



      console.log('1111');
      let token = localStorage.getItem('token');
      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      //  加载店员列表
      let url = 'https://api.dfwlkeji.com/dianyuanguangli/dy_info '

      axios.post(url, {}, { headers: headers }).then((res) => {

        // console.log(res);

        this.tableData = res.data

        // console.log(res.data);


      })




      // 加载所有商家 https://api.dfwlkeji.com/shangjia/chaxun


      let url2 = 'https://api.dfwlkeji.com/shangjia/chaxun'

      axios.post(url2, {}, { headers: headers }).then((res) => {

        // console.log(res);

        // this.tableData = res.data

        console.log(res.data.data);

        this.shangjia_name = res.data.data

        const newArray = this.shangjia_name.filter(item => item.vendor_ID == 303);

        console.log(newArray);



      })

    },//函数


    //  点击添加店员

    shijian_tianjia() {

      // https://api.dfwlkeji.com/dianyuanguangli/dy_tianjia


      console.log('1111');
      let token = localStorage.getItem('token');
      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      //  加载店员列表
      let url = 'https://api.dfwlkeji.com/dianyuanguangli/dy_tianjia '


      var can = {

        shouji: this.shouji,
        mima: this.mima,
        shid: this.shid,
        name: this.name


      }


      console.log(can);

      axios.post(url, can, { headers: headers }).then((res) => {

        console.log(res);

        this.tableData = res.data

        console.log(res.data);


      })



      this.showModal = false;
      this.jaizaishuju()
      this.jaizaishuju()




    },




    openModal() {
      this.showModal = true;
      console.log('通天塔');
    },
    closeModal() {
      this.showModal = false;
      this.xiugai_showModal = false

    },


    //  点击提交修改

    dianji_xiugai(id) {

      console.log(id);
      console.log(this.bianji);


      // dy_xiugai

      console.log('1111');
      let token = localStorage.getItem('token');
      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      //  加载店员列表
      let url = 'https://api.dfwlkeji.com/dianyuanguangli/dy_xiugai '


      var can = {

        shouji: this.bianji.shouji,
        mima: this.bianji.mima,
        shid: this.bianji.SH_ID,
        name: this.bianji.name,
        id:this.bianji.id


      }


      console.log(can);

      axios.post(url, can, { headers: headers }).then((res) => {

        console.log(res);

        this.tableData = res.data

        console.log(res.data);


      })


      this.xiugai_showModal=false
      this.jaizaishuju()
      this.jaizaishuju()




    },





    //  点击编辑的
    handleEdit(index, row) {
      // console.log(index, row);
      this.bianji = row
      this.xiugai_showModal = true


    },


 //  删除店员
    handleDelete(index, row) {
      // console.log(index, row);

      console.log(row.id);

      let token = localStorage.getItem('token');
 
      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      //  加载店员列表
      let url = 'https://api.dfwlkeji.com/dianyuanguangli/dy_delete '


      var can = {
        id:row.id

      }


     

      axios.post(url, can, { headers: headers }).then((res) => {

        location.reload();


      })



 




    }
  }
}
</script>


<style></style>