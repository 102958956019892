<template>
  <el-container class="ZcuoCe">
    <!-- //引用左侧组件 -->
    <ZuoCe />

    <el-container>




      <!-- //面包屑 -->
      <el-header>


        <MianBaoXie />

      </el-header>





      <!-- ///导入路由指定页面 -->
      <el-main> <router-view /></el-main>


    </el-container>
  </el-container>
</template>

<script>

import ZuoCe from '../zhuye/ZuoCe.vue';
import MianBaoXie from '../zhuye/MianBaoXie.vue';

import axios from "axios";

export default {
  components: {
    ZuoCe,
    MianBaoXie
  },


  data() {
    return {

      yonghu: ""
    }
  },


  created() {

    console.log("开始验证");


    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    // console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .get("https://api.dfwlkeji.com/my/userinfo", { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑

        console.log("主页");

        console.log(response.data.message);

        if (response.data.message=="身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });

          
          
        }


      

        // this.yonghu = response.data.data;


      })
      .catch((error) => {
        // 请求失败处理逻辑
        // console.error(error);




      });






  }











}
</script>

<style>
.ZuoCe {


  height: auto;
  /* 高度自适应 */
  margin-left: 100px;
  /* 向右调节10像素 */
  margin-right: -20px;
  /* 向左调节20像素 */
}
</style>