<template>
  <div class="da">
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <el-alert v-if="showSuccessAlert" title="登录成功" type="success" show-icon :style="{
      position: 'absolute',
      top: '10%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '200px',
      height: '40px',
      textAlign: 'center',
    }"></el-alert>

    <el-alert v-if="showErrorAlert" title="登录失败" type="error" description="请输入正确的账户密码" show-icon :style="{
      position: 'absolute',
      top: '10%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '250px',
      height: '40px',
      textAlign: 'center',
    }"></el-alert>

    <div class="dl_kuang">
      <p class="zi2">东峰外卖管理系统</p>
      <el-form class="dl_loin-dl" label-width="0px">
        <el-form-item>
          <el-input type="text" v-model="LoinForm.LoinName" prefix-icon="el-icon-user-solid"
            placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input type="password" v-model="LoinForm.password" prefix-icon="el-icon-lock"
            placeholder="请输入登录密码"></el-input>
        </el-form-item>
        <el-form-item class="login1" prop="code" autocomplete="off">
          <div style="display: flex">
            <input type="text" name="hiddenCaptchaInput" style="display: none" />
            <el-input placeholder="请输入验证码" prefix-icon="el-icon-circle-check" size="medium" style="flex: 1"
              v-model="LoinForm.code" autocomplete="off"></el-input>
            <div style="flex: 1; height: 36px">
              <CaptchaComponent @update:value="getCaptchaCode" ref="captchaInput" />
            </div>
          </div>
        </el-form-item>
        <el-form-item >
          <el-button class="dl_anjian" type="primary" @click="onSubmit">立即登录</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="beian">
      <p class="lf">陆丰市东峰网络科技有限公司</p>
      <el-link :underline="false" class="yue" href="https://beian.miit.gov.cn/#/Integrated/index"
        type="primary">备案号:粤ICP备2022100622号</el-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CaptchaComponent from "@/components/ValidCode.vue";

export default {
  name: "Login",
  data() {
    return {
      LoinForm: {
        LoinName: "",
        password: "",
        code: "",
      },
      errorMessage: "", // 错误提示信息
      showSuccessAlert: false, // 控制成功提示框的显示
      showErrorAlert: false, // 控制错误提示框的显示
    };
  },
  components: {
    CaptchaComponent,
  },
  methods: {
    onSubmit() {
      console.log(this.$store.state.nihao);

      console.log(this.$store.state.nihao);

      if (!this.LoinForm.LoinName || !this.LoinForm.password) {
        this.errorMessage = "";
        this.showErrorAlert = true;
        setTimeout(() => {
          this.errorMessage = "";
          this.showErrorAlert = false;
          location.reload();
        }, 2000); // 2秒后刷新页面
        return;
      }

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        withCredentials: true,
      };

      axios
        .post(
          "https://api.dfwlkeji.com/api/login",
          {
            username: this.LoinForm.LoinName,
            password: this.LoinForm.password,
            code: this.LoinForm.code,
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          let token = response.data.token;

          console.log(token);

          // 将token保存到浏览器的本地存储中
          localStorage.setItem("token", token);

          // console.log( this.$store.state.token );
          // 修改 仓库

          if (response.data.status === 0) {
            // console.log("登录成功"); // 打印登录成功提示
            // 登录成功，显示成功提示框
            this.showSuccessAlert = true;
            // 延迟一段时间后跳转到下一个页面
            setTimeout(() => {
              this.$router.push({ path: "../zhuye/GaiKuo" });
            }, 2000); // 延迟2秒后跳转
          } else {
            console.log("登录失败"); // 打印登录失败提示
            // 登录失败，显示错误提示框
            this.errorMessage = "账号或密码错误";
            this.showErrorAlert = true;
            // 延迟一段时间后刷新页面
            setTimeout(() => {
              this.errorMessage = "";
              this.showErrorAlert = false;
              location.reload();
            }, 2000); // 延迟2秒后刷新页面
          }
        });
    },

    getCaptchaCode(captchaCode) {
      this.LoinForm.code = captchaCode;
    },
  },
  mounted() {
    // 监听键盘事件，按下回车键触发登录
    document.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        this.onSubmit();
      }
    });
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

/* //大的登录框 */
/* .denglu {
border-radius: 5px;
width: 1000px;
height: 590px;
background-color: rgb(255, 138, 21);
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%,-50%);
} */

/* .zi{
font-size: 35px;
font-family: "Arial", sans-serif;
font-weight: 700;
position: absolute;
color: aliceblue;
top: 10%;
left: 65%;
width: 30%; 
transform: translate(-10%, -120%);

} */

.zi2 {
  font-size: 20px;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  position: absolute;
  color: rgb(2, 2, 2);
  top: 10%;
  left: 30%;
  width: 10;
  transform: translate(-10%, -100%);
}

.dl_kuang {
  border-radius: 5px;
  width: 340px;
  height: 380px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
}

.beian {
  border-radius: 5px;
  width: 1920px;
  height: 108px;
  background-color: rgb(235, 237, 240);
  position: absolute;
  left: 50%;
  top: 91%;
  transform: translate(-50%, -30%);
}

.lf {
  font-size: 14px;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  position: absolute;
  color: rgb(24, 166, 137);
  top: 30%;
  left: 50%;
  width: 30%;
  transform: translate(-10%, -120%);
}

.yue {
  font-size: 14px;
  font-family: "Arial", sans-serif;
  font-weight: 700;
  position: absolute;
  top: 60%;
  left: 39.8%;
  width: 30%;
  transform: translate(-10%, -120%);
}

.loin1 {
  top: -10%;
  left: -50%;
  width: 80%;
  transform: translate(10%, 200%);
}

.da {
  width: 100%;
  height: 90vh;
  background-image: url("https://bpic.51yuansu.com/pic3/cover/03/05/90/5b2a3c27b83fc_610.jpg?x-oss-process=image/resize,h_360,m_lfit/sharpen,100");
  /* 替换成您的图片路径 */
  background-size: cover;
  /* 图片铺满整个容器 */
  background-repeat: no-repeat;
  /* 不重复平铺图片 */
  background-position: center center;
  /* 图片居中 */
  overflow: auto;
  /* 或其他处理溢出的方式 */
}

.dl_anjian{
  top: 20%;
  left: 40%;
  width: 100%;
  transform: translate(50%, 30%);
}

.dl_loin-dl {
  top: 40%;
  left: 70%;
  width: 75%;
  transform: translate(20%, 30%);
}

/* .yanzheng{
position: absolute;
top: 65%;
left:0%;
width: 50%; 
transform: translate(0%, 45%);

} 

.yanzheng2{
position: absolute;
top: 65%;
left:50%;
width: 50%; 
transform: translate(0%, 45%);

}  */
</style>
