// store.js

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const TOKEN_KEY = 'token';

const store = new Vuex.Store({
  state: {
    token: null
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem(TOKEN_KEY, token);
    },
    clearToken(state) {
      state.token = null;
      localStorage.removeItem(TOKEN_KEY);
    }
  },
  // ...
});

export default store;