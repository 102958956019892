<template>
  <div id="app">
    <MapComponent/>
  </div>
</template>

<script>
import MapComponent from './MapComponent.vue'

export default {
  name: 'App',
  components: {
    MapComponent
  }
}
</script>