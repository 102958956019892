import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoinView from '../views/Loin.vue';
import HoutaiView from '../views/Houtai.vue';
// 后台主页
import ZhuYeView from '../views/zhuye/ZhuYe.vue';
// 后台左侧组件
import ZuoCeView from '../views/zhuye/ZuoCe.vue';
// 概括页面
import GaiKuoView from '../views/zhuye/GaiKuo.vue';
// 订单状态
import DingDanView from '../views/zhuye/DingDan.vue';
// 商家列表
import LieBiaoView from '../views/zhuye/LieBiao.vue';
// 商家入住页面
import RuZhuView from '../views/business/RuZhu.vue';
// 商品编辑页面
import Sp_bianjiView from '../views/business/Sp_bianji.vue';
// 运营数据的页面
import ShuJuView from '../views/zhuye/ShuJu.vue';
// 店员页面
import DianYuanView from '../views/zhuye/DianYuan.vue';
// 配送员页面
import PeiSongYuanView from '../views/zhuye/PeiSongYuan.vue';
// 顾客列表
import GuKeBiaoView from '../views/zhuye/GuKeBiao.vue';
// 售后评价页面
import ShouHouView from '../views/zhuye/ShouHou.vue';
// 应用工具列表
import YingYongView from '../views/zhuye/YingYong.vue';
// 入住左侧导航栏
import RuZhuZuoCeView from '../views/business/RuZhuZuoCe.vue';
///独立商家页数据页面
import D_ShuJuView from '../views/business/D_ShuJu.vue';
///独立商家页商品页面
import ShangPinView from '../views/business/ShangPin.vue';
///独立页面商品分类页面
import D_fenleiView from '../views/business/D_fenlei.vue';
///独立页面店员
import D_DianYuanView from '../views/business/D_DianYuan.vue';
///独立页面评价页面
import PingJiaView from '../views/business/PingJia.vue';
///独立页面打印机
import DaYinView from '../views/business/DaYin.vue';
///独立页面设置
import SheZhiView from '../views/business/SheZhi.vue';
// 入住顶部样式
import S_shangjiadingbuView from '../views/business/S_shangjiadingbu.vue';
//高德地图引入
import mapView from '../views/business/map.vue';
//高德地图引入2
import MapComponentView from '../views/MapComponent.vue';
///基础设置
import Shzhi_JichuView from '../views/zhuye/Shzhi_Jichu.vue';






































// 默认404提示页
import NotFoundView from '../views/NotFound.vue';

Vue.use(VueRouter);



























const routes = [
  {
    path: '/',
    redirect: '/Loin',
    name: 'home',
    component: HomeView
  },
  // 临时登录页面
  {
    path: '/loin',
    name: 'Loin',
    component: LoinView
  },
  // 商家独立页面
  {
    path: '/business/RuZhu',
    name: 'RuZhu',
    component: RuZhuView
  },
  
  ///商家页面顶部样式
  {
    path: '/business/S_shangjiadingbu',
    name: 'S_shangjiadingbu',
    component: S_shangjiadingbuView
  },
////houtai测试文件
 // 商家独立页面
 {
  path: '/Houtai',
  name: 'Houtai',
  component: HoutaiView
},
{
  path: '/MapComponent',
  name: 'MapComponent',
  component: MapComponentView
},
































  // 主页入口包含整个主页的页面以及导航，这是头部！！！
  {
    path: '/ZhuYe',
    name: 'ZhuYe',
    meta: { title: "平台概括" },
    component: ZhuYeView,
    // 子路由
    children: [
      // 概括
      {
        path: 'GaiKuo',
        name: 'GaiKuo2',
        meta: { title: "平台概括" },
        component: () => import("../views/zhuye/GaiKuo.vue")
      },
      // 订单状态
      {
        path: 'DingDan',
        name: 'DingDan2',
        meta: { title: "订单状态" },
        component: () => import("../views/zhuye/DingDan.vue")
      },
      // 商家列表
      {
        path: 'LieBiao',
        name: 'LieBiao2',
        meta: { title: "商家列表" },
        component: () => import("../views/zhuye/LieBiao.vue")
      },
      // 运营数据页面
      {
        path: 'ShuJu',
        name: 'ShuJu2',
        meta: { title: "运营数据" },
        component: () => import("../views/zhuye/ShuJu.vue")
      },
      // 子菜单中的店员
      {
        path: 'DianYuan',
        name: 'DianYuan2',
        meta: { title: "店员管理" },
        component: () => import("../views/zhuye/DianYuan.vue")
      },
      // 子菜单中的配送员
      {
        path: 'PeiSongYuan',
        name: 'PeiSongYuan2',
        meta: { title: "配送员" },
        component: () => import("../views/zhuye/PeiSongYuan.vue")
      },
      // 子菜单中的顾客列表
      {
        path: 'GuKeBiao',
        name: 'GuKeBiao2',
        meta: { title: "顾客列表" },
        component: () => import("../views/zhuye/GuKeBiao.vue")
      },
      // 子菜单售后评价页面
      {
        path: 'ShouHou',
        name: 'ShouHou2',
        meta: { title: "售后评价" },
        component: () => import("../views/zhuye/ShouHou.vue")
      },
      // 子菜单应用管理页面
      {
        path: 'YingYong',
        name: 'YingYong2',
        meta: { title: "应用列表" },
        component: () => import("../views/zhuye/YingYong.vue")
      },


      ///基础设置
      {
        path: 'Shezhi_Jichu',
        name: 'Shezhi_Jichu2',
        meta: { title: "基础设置" },
        component: () => import("../views/zhuye/Shzhi_Jichu.vue")
      },








    ]
  },
  // 主页入口包含整个主页的页面以及导航，这是尾部！！！




  // 这是商家入住——加子路由头部
  // 入住商家编辑页


  {
    path: '/business',
    name: 'RuZhu',
    component: RuZhuView,
    children: [
      {
        path: 'RuZhuBianji',
        name: 'RuZhuBianji2',

        component: () => import("../views/business/Sp_bianji.vue")
      },


      {
        path: 'D_ShuJu',
        name: 'D_ShuJu',

        component: () => import("../views/business/D_ShuJu.vue")
      },
      {
        path: 'D_DianYuan',
        name: 'D_DianYuan',

        component: () => import("../views/business/D_DianYuan.vue")
      },

      {
        path: 'ShangPin',
        name: 'ShangPin',

        component: () => import("../views/business/ShangPin.vue")
      },

      {
        path: 'D_fenlei',
        name: 'D_fenlei',

        component: () => import("../views/business/D_fenlei.vue")
      },

      {
        path: 'D_DianYuan',
        name: 'D_DianYuan',

        component: () => import("../views/business/D_DianYuan.vue")
      },

      {
        path: 'PingJia',
        name: 'PingJia',

        component: () => import("../views/business/PingJia.vue")
      },

      {
        path: 'SheZhi',
        name: 'SheZhi',

        component: () => import("../views/business/SheZhi.vue")
      },
      {
        path: 'DaYin',
        name: 'DaYin',

        component: () => import("../views/business/DaYin.vue")
      },

      {
        path: 'Sp_bianji',
        name: 'Sp_bianji',

        component: () => import("../views/business/Sp_bianji.vue")
      },











    ]
  },



  // 这是商家入住——加子路由尾部






















  ///独立页面数据
  {
    path: '/business/D_ShuJu',
    name: 'D_ShuJu',
    component: D_ShuJuView
  },

  ///独立页面店员
  {
    path: '/business/D_DianYuan',
    name: 'D_DianYuan',
    component: D_DianYuanView
  },

  ///独立页面商品
  {
    path: '/business/ShangPin',
    name: 'ShangPin',
    component: ShangPinView
  },

  ///独立页面分类
  {
    path: '/business/D_fenlei',
    name: 'D_fenlei',
    component: D_fenleiView
  },

  ///独立页面店员
  {
    path: '/business/D_DianYuan',
    name: 'D_DianYuan',
    component: D_DianYuanView
  },

  ///独立页面评价页面
  {
    path: '/business/PingJia',
    name: 'PingJia',
    component: PingJiaView
  },

  ///独立页面设置页面
  {
    path: '/business/SheZhi',
    name: 'SheZhi',
    component: SheZhiView
  },

  ///独立页面打印机
  {
    path: '/business/DaYin',
    name: 'DaYin',
    component: DaYinView
  },











  // 左侧导航栏全局引入
  {
    path: '/zhuye/ZuoCe',
    name: 'ZuoCe',
    component: ZuoCeView
  },
  // 商家列表
  {
    path: '/zhuye/LieBiao',
    name: 'LieBiao',
    component: LieBiaoView
  },
  // 概括页面
  {
    path: '/zhuye/GaiKuo',
    name: 'GaiKuo',
    meta: { title: "平台概括" },
    component: GaiKuoView
  },
  // 运营数据页面
  {
    path: '/zhuye/ShuJu',
    name: 'ShuJu',
    meta: { title: "运营数据" },
    component: ShuJuView
  },
  // 订单状态页面
  {
    path: '/zhuye/DingDan',
    name: 'DingDan',
    meta: { title: "订单状态" },
    component: DingDanView
  },
  // 入住之后的商家设置页面
  {
    path: '/business/Sp_bianji',
    name: 'Sp_bianji',
    component: Sp_bianjiView
  },
  // 入住页面左侧导航栏
  {
    path: '/business/RuZhuZuoCe',
    name: 'RuZhuZuoCe',
    component: RuZhuZuoCeView
  },
  // 店员页面
  {
    path: '/zhuye/DianYuan',
    name: 'DianYuan',
    component: DianYuanView
  },
  // 配送员页面
  {
    path: '/zhuye/PeiSongYuan',
    name: 'PeiSongYua',
    component: PeiSongYuanView
  },
  // 顾客列表
  {
    path: '/zhuye/GuKeBiao',
    name: 'GukeBiao',
    component: GuKeBiaoView
  },
  // 售后评价
  {
    path: '/zhuye/ShouHou',
    name: 'ShouHou',
    component: ShouHouView
  },
  // 应用列表
  {
    path: '/zhuye/YingYong',
    name: 'YingYong',
    component: YingYongView
  },

  //商品编辑页面
  {
    path: '/zhuye/YingYong',
    name: 'YingYong',
    component: YingYongView
  },


  ///高德地图获取IP
  {
    path: '/business/map',
    name: 'map',
    component: mapView
  },

    // 基础设置
    {
      path: '/zhuye/Shzhi_Jichu',
      name: 'Shzhi_Jichu',
      meta: { title: "运营数据" },
      component: ShuJuView
    },













  // 默认404提示
  {
    path: '/*',
    name: 'NotFound',
    component: NotFoundView
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;