<template >
  <el-aside class="aside2" width="185px">

    <el-col :span="24" style="margin-left: 10px; " class="shanghuhoutai custom-height">

      <!-- ////店铺头像 -->
      <div class="ruzhuyemianshangjiatouxiang"></div>

      <h5 class="adjust-position">{{ shangjia.vendor_MC }}</h5>

      <el-menu :default-active=sj+bb background-color="#304156" text-color="#fff" active-text-color="#67C23A" router>

        <el-menu-item :index=sj+bb>
          <i class="el-icon-pie-chart"></i>
          <span slot="title">数据</span>
        </el-menu-item>

        <el-menu-item :index=sp+bb>
          <i class="el-icon-shopping-cart-full"></i>
          <span slot="title">商品</span>
        </el-menu-item>

        <el-menu-item  :index=fl+bb>
          <i class="el-icon-menu"></i>
          <span slot="title">分类</span>
        </el-menu-item>

        <el-menu-item :index=dy+bb>
          <i class="el-icon-s-custom"></i>
          <span slot="title">店员</span>
        </el-menu-item>

        <el-menu-item :index=pj+bb>
          <i class="el-icon-s-comment"></i>
          <span slot="title">评价</span>
        </el-menu-item>

        <el-menu-item :index=sz+bb>
          <i class="el-icon-setting"></i>
          <span slot="title">设置</span>
        </el-menu-item>


        <el-menu-item :index=dyy+bb>
          <i class="el-icon-printer"></i>
          <span slot="title">打印机</span>
        </el-menu-item>




      </el-menu>









    </el-col>











  </el-aside>
</template>

<script>

import axios from "axios";


export default {


  data() {
    return {

      shangjia: "",
      iid:'',
      sj:'D_ShuJu?id=',
      sp:'ShangPin?id=',
      fl:'D_fenlei?id=',

      dy:'D_DianYuan?id=',
      pj:'PingJia?id=',
      sz:'SheZhi?id=',
      dyy:'DaYin?id=',



      

      bb: this.iid


    

    };
  },



  created() {

    const id = this.$route.query.id; // 获取id参数的值
    console.log(id);
    // console.log('iii');

    this.bb=id
    console.log(this.bb);



    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    // console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/shangjia/sousuo", {

        gjc: id



      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑
        console.log(response);
        console.log('chnrdgg');

        // console.log("ccgg");

        this.shangjia = response.data.data[0];


        console.log(response.data.data[0].vendor_MC);



      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);


      });












  }











}
</script>






<style>
.aside2 {
  width: 200px;
  height: 850px;

  background: #304156;



}
</style>