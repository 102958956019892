<template>
  <div>
    <!-- 搜索框 -->

    <el-container>
      <el-header>

        <div class="container">


          <!-- /////添加商家 -->



          <!-- <el-button type="primary" icon="el-icon-plus" @click="openQitePage">添加商户</el-button> -->

          <el-button type="primary" icon="el-icon-plus" @click="dialogFormVisible = true">新建分类</el-button>

          <el-button type="info" icon="el-icon-upload" style="margin-top: 0px; margin-left: 20px;" @click="quanbushangjia"
            plain>导入分类</el-button>




          <el-button type="success" icon="el-icon-s-grid" style="margin-top: 0px; margin-left: 50px;"
            @click="quanbushangjia" plain>所有分类</el-button>


          <!-- 修改的弹窗 -->
          <el-dialog title="修改商品分类信息" @click="fenlei_tc = false" :visible.sync="fenlei_tc">

            <div style="display: flex;
                justify-content: center;
                align-items: center;
                height: 20vh; /* 设置容器高度为视口高度，使表单垂直居中 */">


              <div class="form-group">
                <label for="fenlei_name">分类名称</label>
                <input class="shur" type="text" id="fenlei_name" v-model="xg_fenlei_name" required>
              </div>


              <div class="form-group">
                <label for="xg_fenlei_xuhao">排序：</label>
                <input class="shur" type="text" id="xg_fenlei_xuhao" v-model="xg_fenlei_xuhao" required>
              </div>


              <el-button type="primary" @click="tijiaoxiugai" round>提交修改</el-button>






            </div>

          </el-dialog>



          <!-- 新建的弹窗 -->
          <el-dialog title="请填写商品分类信息" @click="dialogFormVisible = false" :visible.sync="dialogFormVisible">





            <div style="display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 设置容器高度为视口高度，使表单垂直居中 */">



              <form @submit.prevent="handleSubmit" class="form">



                <div class="form-group">
                  <label for="fenlei_name">分类名称</label>
                  <input class="shur" type="text" id="fenlei_name" v-model="fenlei_name" required>
                </div>


                <div class="form-group">
                  <label for="fenlei_paixu">排序：</label>
                  <input class="shur" type="text" id="fenlei_paixu" v-model="fenlei_paixu" required>
                </div>







                <button type="submit" class="submit-button">提交</button>

              </form>

            </div>

            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <!-- <el-button type="primary" @click="navigateToNextPage">确 定</el-button> -->
            </div>



          </el-dialog>










        </div>
      </el-header>

      <el-main>
        <!-- /////表格 -->

        <div>
          <!-- <el-table :data="shangjia" style="width: 100%;" fixed="left" > -->

          <el-table :data="shangjia" style="width: 100%" max-height="670">

            <el-table-column fixed prop="Fenlei_xuhao" label="序号" width="150px">
            </el-table-column>






            <el-table-column prop="Fenlei_name" label="分类名称" width='300px'>
            </el-table-column>







            <el-table-column prop="Fenlei_zhuangtai" label="显示/隐藏" width="350px">
              <template slot-scope="scope">
                <el-switch :value="scope.row.Fenlei_zhuangtai === 1" active-text="显示" inactive-text="隐藏"
                  @change="xianshi(scope.row.vendor_ID, scope.row.Fenlei_zhuangtai)"></el-switch>
              </template>
            </el-table-column>





            <el-table-column label="管理" width="225px">


              <template slot-scope="scope">
                <!-- <input type="button" value="管理" @click="handleManage(scope.row.vendor_ID)"> -->
                <el-button type="primary" icon="el-icon-setting" @click="handleManage(scope.row.Fenlei_id)">修改</el-button>
              </template>



            </el-table-column>



            <el-table-column label="操作" width="225px">


              <template slot-scope="scope">
                <!-- <input type="button" value="管理" @click="handleManage(scope.row.vendor_ID)"> -->
                <el-button style="background: #F56C6C; border: 0px;" type="primary" icon="el-icon-delete"
                  @click="shanchushangjia(scope.row.Fenlei_id)">删除</el-button>
              </template>



            </el-table-column>


          </el-table>
        </div>






      </el-main>





    </el-container>
  </div>
</template>

<script>
import axios from "axios";



export default {
  data() {
    return {

      xg_fenlei_name: '', //修改时弹窗显示的
      xg_fenlei_xuhao: '',



      shangjia: "",//  分类列表数据

      jieshoushuju: "",
      value: "100",
      zhuangtai: true,
      dialogTableVisible: false,
      dialogFormVisible: false,
      fenlei_tc: false,
      form: {
        name: '',
        dianhua: '',
        xuhao: '',
        logo: '',
        fenlei: {
          zhu: "",
          fu: ""
        },





      },

      fenleiweiyiID: '',// 分类唯一的id

      shangjia_Id: '', // 分类所属商家的id


      previewURL: '', //logo

      sousuo: {
        ssgjc: ''


      },

      uploadUrl: 'https://example.com/upload', // 替换为你的上传接口URL


      formLabelWidth: '120px'
    };
  },

  /////



  // watch: {
  //   value(newValue) {
  //     // 在开关状态改变时触发
  //     console.log("开关状态改变:", newValue);
  //   },

  // },

  created() {

    // 在组件创建时执行
    console.log("组件创建");

    const id = this.$route.query.id; // 获取id参数的值
    // console.log(id);

    this.shangjia_Id = id

    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/fenlei/chaxun", {

        sjid: this.shangjia_Id


      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑


        // console.log("我是列表的");

        if (response.data.message == "身份认证失败！th验证失败") {

          console.log("mytk");
          this.$router.push({ path: "../Loin" });



        }

        console.log(response);


        //  从大到小排列
        response.data.data.sort(function (a, b) {
          return b.Fenlei_xuhao - a.Fenlei_xuhao;
        });


        this.shangjia = response.data.data;


        // console.log(arr);












      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);
      });
  },


  mounted() {
    // 在组件挂载到DOM后执行
    console.log("组件挂载");
    this.startTimer();
  },


  updated() {
    // 在组件更新后执行
    console.log("组件更新");
  },

  destroyed() {
    // 在组件销毁前执行
    console.log("组件销毁");
    this.stopTimer();
  },

  methods: {



    //  提交修改分类

    tijiaoxiugai() {

      console.log(this.xg_fenlei_name);
      console.log(this.xg_fenlei_xuhao);
      console.log(this.fenleiweiyiID);




      console.log("加载分类列表");


      const id = this.$route.query.id; // 获取id参数的值
      // console.log(id);

      this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:token,
          
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/fenlei/xiugai", {



          id: this.fenleiweiyiID,
          fenlei_name: this.xg_fenlei_name,
          fenlei_paixu: this.xg_fenlei_xuhao



        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });

          }

          console.log(response);

          this.shangjia = response.data.data;


          this.fenlei_tc = false  // 关闭修改分类的弹窗

          this.$message.success('修改成功');



          setTimeout(function () {
            location.reload();
          }, 500);











        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });
















    },



    // 上传logo

    handleFileChange(event) {

      this.imageFile = event.target.files[0];
      const file = event.target.files[0];

      console.log(this.imageFile);


      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewURL = e.target.result;
      };

      reader.readAsDataURL(file);








    },



    //---- 添加分类

    handleSubmit() {

      // console.log('tij');
      console.log(this.fenlei_name);
      console.log(this.fenlei_paixu);
      console.log(this.shangjia_Id);



      console.log("加载分类列表");


      const id = this.$route.query.id; // 获取id参数的值
      // console.log(id);

      this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/fenlei/xinjian", {

          fenlei_sjid: this.shangjia_Id,
          fenlei_name: this.fenlei_name,
          fenlei_paixu: this.fenlei_paixu,








        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          console.log(response);

          // this.shangjia = response.data.data;

          this.dialogFormVisible = false  // 关闭弹窗

          this.$message.success('添加成功');



          setTimeout(function () {
            location.reload();
          }, 500);


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });








    },







    // 点击全部商家

    quanbushangjia() {


      this.jiazaishangjialiebiao()

    },








    //删除分类
    shanchushangjia(id) {


      //   // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('删除商家id:', id);

      // console.log(this.shangjia);

      var sjmc = null
      //  提取名称
      for (var i = 0; i < this.shangjia.length; i++) {
        console.log(this.shangjia[i].Fenlei_id
        );

        if (this.shangjia[i].Fenlei_id == id) {

          sjmc = this.shangjia[i].Fenlei_name

          break


        }
      }



      this.$confirm('确定删除该分类吗？ 《 ' + sjmc + " 》 ", ' 提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.shanchuhanshu(id) // 删除商家



        this.$message({

          type: 'success',
          message: '删除成功!'

        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },




    //  删除的函数
    shanchuhanshu(id) {



      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      // console.log(token);

      // console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/fenlei/shanchu", {

          id: id,


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑
          console.log(response);

          // console.log("ccgg");

          this.shangjia = response.data.data;

          // window.location.reload();
          this.jiazaishangjialiebiao()


        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);


        });


    },





    //管理分类

    handleManage(id) {
      // 在这里处理按钮点击事件，可以使用传递的 id 进行相应的操作
      console.log('Manage button clicked for ID:', id);

      console.log('分类id:', id);

      this.fenleiweiyiID = id

      this.fenlei_tc = true

      console.log(this.shangjia);

      var result = this.shangjia.filter(function (obj) {
        return obj.Fenlei_id === id;
      });

      console.log(result[0].Fenlei_name);

      this.xg_fenlei_name = result[0].Fenlei_name
      this.xg_fenlei_xuhao = result[0].Fenlei_xuhao




      // setTimeout(() => {
      //   window.open("../business/D_ShuJu?id=" + id, "_blank");
      // }, 0);


    },














    //  加载分类列表函数

    jiazaishangjialiebiao() {

      console.log("加载分类列表");


      const id = this.$route.query.id; // 获取id参数的值
      // console.log(id);

      this.shangjia_Id = id

      // 从浏览器的本地存储中获取token
      let token = localStorage.getItem('token');

      console.log(token);

      var headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          token,
        Accept: "*/*",
        "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
        Host: "kaifa.beilakeji.top",
        "Accept-Encoding": "gzip, deflate, br",
        Connection: "keep-alive",
      };

      axios
        .post("https://api.dfwlkeji.com/fenlei/chaxun", {

          sjid: this.shangjia_Id


        }, { headers: headers })

        .then((response) => {
          // 请求成功处理逻辑


          // console.log("我是列表的");

          if (response.data.message == "身份认证失败！th验证失败") {

            console.log("mytk");
            this.$router.push({ path: "../Loin" });



          }

          console.log(response);

          this.shangjia = response.data.data;

        })
        .catch((error) => {
          // 请求失败处理逻辑

          // console.error(error);
        });



    },

















  },
};
</script>

<style>
.container {
  margin-top: 39px;
}



.moveable-component {
  position: relative;
  top: 100px;
  left: -150px;
}

.moveable {
  top: -103px;
  bottom: 30px;
  transform: translate(150%, 105%);



}

.dianpumingcheng {

  position: relative;
  top: -50px;
  bottom: 50px;
  transform: translate(-15%, 10%);

}

.shangjiadianhua {

  position: relative;
  top: -90px;
  bottom: 50px;
  transform: translate(26%, 10%);

}


.dianshu {

  position: relative;
  top: -50px;
  bottom: 50px;
  transform: translate(26%, 10%);

}


.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* 调整容器的高度 */
  transform: translate(-40%, 10%);
}

.loading-image {
  width: 100px;
  /* 调整图片的宽度 */
  height: 100px;
  /* 调整图片的高度 */
}

.upload-demo {

  position: relative;
  top: -165px;
  bottom: 50px;
  transform: translate(40%, 10%);



}







.form-container {
  width: 100%;
  /* 设置表单宽度为100% */
  height: 100%;
  /* 设置表单高度为100% */
  overflow: hidden;
  /* 防止表单元素超出弹窗 */
}




/* //biaod */


.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}

/* input,
select {

} */


.shur {

  padding: 8px;
  border: 1px solid #2fa9f0;
  border-radius: 5px;
  width: 80%;


}




.submit-button {
  padding: 10px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}



.file-input {
  position: relative;
  display: inline-block;
}

.input {
  position: absolute;
  left: -9999px;
}

.button {
  display: inline-block;
  padding: 8px 8px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 8px;

}


.button:hover {
  background-color: #0056b3;
  border-radius: 12px;
  color: rgb(247, 246, 250);


}

.file-name {
  margin-left: 6px;
}
</style>
