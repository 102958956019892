<template>
  <div class="bianjishangjiayemiandinbu">
    <img class="img99" :src=shangjia.vendor_LOGO  alt="">
    <h5 class="dianmingchengbianjiyemian">{{ shangjia.vendor_MC }}</h5>

  </div>
</template>

<script>
import axios from "axios";


export default {


  data() {
    return {

      shangjia: "",


    };
  },



  created() {

    const id = this.$route.query.id; // 获取id参数的值
    console.log(id);
    // console.log('iii');



    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    // console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/shangjia/sousuo", {

        gjc: id



      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑
        console.log(response);
        console.log('chnrdgg');

        // console.log("ccgg");

        this.shangjia = response.data.data[0];

        console.log(response.data.data[0].vendor_MC);



      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);


      });












  }











}
</script>

<style>
.bianjishangjiayemiandinbu {

  width: 200px;
  background-color: #304156;
  transform: translate(-17.5%, 0%);
  height: 200px;



}

.img99 {
  width: 50px;
  height: 50px;
  transform: translate(70px, 30px);
  /* 向右移动50像素，向下移动20像素 */
}



.dianmingchengbianjiyemian {
  position: relative;
  left: 50%;
  transform: translate(-50%, 10px);
  width: 200px;
  color: white;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>