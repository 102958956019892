<template>
  <div>
    <p v-if="isWithinBounds">该位置在指定区域内</p>
    <p v-else>该位置不在指定区域内</p>
    <p>详细地址信息: {{ addressInfo }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      locationToCheck: {
        longitude: 115.83907105395508,
        latitude: 22.83534662861896
      },
      bounds: {
        southwest: {
          longitude: 116.387123,
          latitude: 39.900000
        },
        northeast: {
          longitude: 116.4075,
          latitude: 39.917222
        }
      },
      isWithinBounds: false,
      addressInfo: null
    };
  },
  methods: {
    isLocationWithinBounds(location, bounds) {
      // 实现 isLocationWithinBounds 方法的逻辑
    },
    async getAddressInfo(location) {
      try {
        const response = await axios.get(`${amapSearchURL}${location.longitude},${location.latitude}`);
        if (response.data.status === '1') {
          this.addressInfo = response.data.regeocode;
        }
      } catch (error) {
        console.error('高德地图API调用失败:', error);
      }
    },
    async checkLocationAndGetAddress() {
      this.isWithinBounds = this.isLocationWithinBounds(this.locationToCheck, this.bounds);
      if (this.isWithinBounds) {
        await this.getAddressInfo(this.locationToCheck);
      } else {
        await this.getAddressInfo(this.locationToCheck);
      }
    }
  },
  mounted() {
    this.checkLocationAndGetAddress();
  }
};
</script>