<template>
  <el-tabs v-model="activeName" type="card" @tab-click="handleClick">

    <!-- 基础设置顶部 -->


    <el-tab-pane label="基础设置" name="first">

      <form @submit.prevent="shangjiaxinxi" class="form" style="width: 100%;">

        <div class="input-dianpumingcheng">
          <h5>店铺名称</h5>
          <el-input v-model="inputValue_mc" size="small" id="name" style="width: 400px;"></el-input>

          <!-- <input class="shur" type="text" id="name" v-model="name" required> -->

        </div>

        <div class="input-dianpumingcheng">
          <h5>门店电话</h5>
          <el-input v-model="inputValue_dh" size="small" style="width: 400px;"></el-input>
        </div>

        <div class="input-dianpumingcheng">
          <h5>详细地址</h5>
          <el-input v-model="inputValue_dizhi" size="small" style="width: 400px;"></el-input>
        </div>
        <div class="input-dianpumingcheng">
          <h5>商家排序</h5>
          <el-input v-model="inputValue_paixu" size="small" style="width: 400px;"></el-input>
        </div>
        <!-- ////坐标 -->
        <div class="zuobiaoshurukuang">
          <h5>经度纬度</h5>
          <el-input v-model="inputValue_zuobiao1" size="small" style="width: 150px;"></el-input>
          <!-- <h5>纬度</h5> -->
          <el-input v-model="inputValue_zuobiao2" size="small" style="width: 150px;"></el-input>



          <!-- <el-button @click="openMapDialog">打开地图弹窗</el-button> -->
          <el-dialog title="地图弹窗" style=" height: 850px;" :visible.sync="dialogVisible" width="40%">


            <!-- <MapComponent @mapData="handleMapData"  /> -->

            <div style="height: 1000px; margin-top: -5%; ">


              <MapComponent @mapData="handleMapData" />

              <el-button @click="gbtc" style="float: right; margin-top: 20px;" type="primary">确定</el-button>



            </div>

            <div>



            </div>





          </el-dialog>




          <el-button type="primary" size="small" style="margin-left: 10px;" @click="openMapDialog">获取店铺坐标</el-button>
        </div>

        <div class="xuanzeqishangjiayemian">
          <h5 style="display: inline-block; margin-right: 300px;">运营类目</h5>



          <el-select v-model="value1" placeholder="主营类目" style="display: inline-block; margin-right: 10px;">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-select v-model="value2" placeholder="辅营类目" style="display: inline-block;">
            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <div class="time-picker-row">
            <h5>营业时间</h5>
            <el-time-picker v-model="Time1" placeholder="选择开始时间" style="margin-left: 300px;"
              :picker-options="{ format: 'HH:mm:ss' }"></el-time-picker>

            <!-- <label>结束时间：</label> -->
            <el-time-picker v-model="Time2" placeholder="选择结束时间" style="margin-left: 8px;"
              :picker-options="{ format: 'HH:mm:ss' }"></el-time-picker>
          </div>


          <div class="time-picker-row">
            <h5>营业时间2</h5>
            <el-time-picker v-model="Time3" placeholder="选择开始时间" style="margin-left: 300px;"
              :picker-options="{ format: 'HH:mm:ss' }"></el-time-picker>

            <!-- <label>结束时间：</label> -->
            <el-time-picker v-model="Time4" placeholder="选择结束时间" style="margin-left: 8px;"
              :picker-options="{ format: 'HH:mm:ss' }"></el-time-picker>
          </div>



        </div>


        <!-- 头像 -->
        <div class="avatar-rjstx">
          <div class="text">
            <h5>店铺LOGO</h5>
          </div>
          <div class="avatar-sjtxaa" style="margin-left: 300px;">

            <img style="border: 10px; padding-bottom: 10px;" width="60" height="60" ref="existingImage" v-if="previewURL"
              v-bind:src="previewURL" alt="Preview Image">


          </div>
        </div>

        <!-- LOGO选择按钮 -->
        <div class="logoxuanzeanniu">





          <div class="file-input">
            <input type="file" id="fileInput" class="input" @change="handleFileChange">
            <label for="fileInput" class="button">选择logo</label>
            <span class="file-name">{{ fileName }}</span>
          </div>



        </div>

        <!-- 提交报错按钮 -->

        <div class="logoxuanzeanniu2">


          <button type="submit" class="submit-button" style="background:#67C23A;">提交保存</button>
          <!-- <el-button type="success">成功按钮</el-button> -->


        </div>





      </form>


    </el-tab-pane>







    <!-- 基础设置尾部 -->

    <el-tab-pane label="分账配置" name="second">

      <form @submit.prevent="shangjiaxinxi" class="form" style="width: 100%;">

        <div class="input-dianpumingcheng">
          <h5>支付宝姓名</h5>
          <el-input v-model="txname" size="small" id="name" style="width: 100px;"></el-input>

          <!-- <input class="shur" type="text" id="name" v-model="name" required> -->

        </div>

        <div class="input-dianpumingcheng">
          <h5>支付宝账号</h5>
          <el-input v-model="txzhanghao" size="small" style="width: 120px;"></el-input>
        </div>

        <div class="input-dianpumingcheng">
          <h5>商家服务费</h5>
          <el-input v-model="dianshu" size="small" style="width: 50px;"></el-input>%

          
          
      
        </div>
        <div class="input-dianpumingcheng">
          <h5>商家起送价</h5>
          <el-input v-model="qisongjia"   size="small" style="width: 120px;"></el-input>元
        </div>
    





    

   

        <!-- 提交报错按钮 -->

        <div class="logoxuanzeanniu"  style="float: left;  margin-right: 500px; margin-top: 300px;">


          <button type="submit" class="submit-button" style="background:#67C23A; width: 150px;">提交保存</button>
          <!-- <el-button type="success">成功按钮</el-button> -->


        </div>





      </form>



    </el-tab-pane>

    <el-tab-pane label="外卖设置" name="third">现在还不知道怎么设置啊</el-tab-pane>





    <!-- 营业资质顶部 -->

    <el-tab-pane label="营业资质" name="fourth">

      <!-- 营业执照 -->
      <div class="avatar-rjstx">
        <div class="text">
          <h5>营业执照</h5>
        </div>
        <div class="avatar-zhizhao" style="margin-left: 200px;">
          <img src="../../img/khg.jpg" alt="" width="200" height="100">
        </div>
      </div>

      <!-- 营业执照选择按钮 -->
      <div class="logoxuanzeanniu">

        <el-button type="primary">上传营业执照</el-button>

      </div>


      <!-- 食品卫生许可证 -->
      <div class="avatar-rjstx">
        <div class="text">
          <h5>食品卫生许可证</h5>
        </div>
        <div class="avatar-weishengxukezheng" style="margin-left: 160px;">
          <img src="../../img/khg.jpg" alt="" width="200" height="100">
        </div>
      </div>

      <!-- 营业执照选择按钮 -->
      <div class="logoxuanzeanniu">

        <el-button type="primary">上传卫生许可证</el-button>

      </div>





      <!-- 提交报错按钮 -->

      <div class="logoxuanzeanniu3">

        <el-button type="success">提交保存</el-button>

      </div>





    </el-tab-pane>




    <!-- 营业资质尾部 -->







  </el-tabs>
</template>


<script>

import axios from "axios";
import MapComponent from "../MapComponent.vue";



export default {
  data() {
    return {
      activeName: 'first',
      inputValue_mc: '',
      inputValue_dh: '',
      inputValue_dizhi: '',
      inputValue_paixu: '',
      inputValue_zuobiao1: '',
      inputValue_zuobiao2: '',
      txzhanghao:'',
      txname:'',
      dianshu:'',
      qisongjia:'',



      previewURL: '', //logo



      Time1: '', // 设置为上午9点
      Time2: '',


      Time3: '', // 设置为上午9点
      Time4: '',


      dialogVisible: false,


      // // 假设你拿到的时间戳是 lastModified
      // const lastModifiedTimestamp = 1706468845898;
      // const lastModifiedDate = new Date(1706468845898);


      // Time1: new Date(0, 0, 0, 9, 0), // 设置为上午9点/

      options1: [
        { value: '奶茶甜品', label: '奶茶甜品' },
        { value: '烧烤炸串', label: '烧烤炸串' },
        { value: '火锅炖品', label: '火锅炖品' },
        { value: '肠粉包点', label: '肠粉包点' },
        { value: '披萨汉堡', label: '披萨汉堡' },
        { value: '汤炒粉饭', label: '汤炒粉饭' },
        { value: '排挡小炒', label: '排挡小炒' },
        { value: '本地小吃', label: '本地小吃' }

      ],

      options2: [
        { value: '奶茶甜品', label: '奶茶甜品' },
        { value: '烧烤炸串', label: '烧烤炸串' },
        { value: '火锅炖品', label: '火锅炖品' },
        { value: '肠粉包点', label: '肠粉包点' },
        { value: '披萨汉堡', label: '披萨汉堡' },
        { value: '汤炒粉饭', label: '汤炒粉饭' },
        { value: '排挡小炒', label: '排挡小炒' },
        { value: '本地小吃', label: '本地小吃' }

      ],

      value1: '',
      value2: ''
    };

  },



  components: {

    MapComponent

  },


  //时间周期进入自动加载

  created() {

    const id = this.$route.query.id; // 获取id参数的值
    // console.log(id);
    // console.log('iii');

    this.bb = id
    // console.log(this.bb);



    // 从浏览器的本地存储中获取token
    let token = localStorage.getItem('token');

    // console.log(token);

    var headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization:
        token,
      Accept: "*/*",
      "Postman-Token": "8a6760a8-3136-47b2-92f3-e09e78d9136b",
      Host: "kaifa.beilakeji.top",
      "Accept-Encoding": "gzip, deflate, br",
      Connection: "keep-alive",
    };

    axios
      .post("https://api.dfwlkeji.com/shangjia/sousuo", {

        gjc: id



      }, { headers: headers })

      .then((response) => {
        // 请求成功处理逻辑
        console.log(response);
        console.log('chnrdgg');

        // console.log("ccgg");

        this.inputValue_mc = response.data.data[0].vendor_MC;

        this.inputValue_dh = response.data.data[0].vendor_ShouJi;

        this.inputValue_dizhi = response.data.data[0].vendor_DiZhi;
        this.inputValue_paixu = response.data.data[0].vendor_PaiXU;

        this.previewURL = response.data.data[0].vendor_LOGO;
        this.txname= response.data.data[0].tixian_nanme;
        this.txzhanghao= response.data.data[0].tixian_zhanghao;
        this.dianshu= response.data.data[0].vendor_DianShu;

        this.qisongjia=response.data.data[0].qi_song_jia;




        this.inputValue_zuobiao1 = response.data.data[0].vendor_ZuoBiao.split("/")[0]
        this.inputValue_zuobiao2 = response.data.data[0].vendor_ZuoBiao.split("/")[1]



        // console.log(response.data.data[0].vendor_ShiJian,'shij d ');

        let ruzhushijian = response.data.data[0].vendor_yingyeShiJian // 时间显示的
        let ruzhushijian2 = response.data.data[0].shijian2 // 时间显示的


        if (ruzhushijian) {

          this.Time1 = new Date(ruzhushijian.split('/')[0] * 1)
          this.Time2 = new Date(ruzhushijian.split('/')[1] * 1)


        }


        if (ruzhushijian2) {


          this.Time3 = new Date(ruzhushijian2.split('/')[0] * 1)
          this.Time4 = new Date(ruzhushijian2.split('/')[1] * 1)



        }







        let Flei = response.data.data[0].vendor_FenLei;  //分类 分割

        let Flei1 = Flei.split("/")[0];
        let Flei2 = Flei.split("/")[1];

        // value1: '选项2',

        this.value1 = Flei1
        this.value2 = Flei2





        // console.log(Flei2);








        // console.log(response.data.data[0].vendor_MC);



      })
      .catch((error) => {
        // 请求失败处理逻辑

        // console.error(error);


      });












  },



  // 触发生效

  methods: {


    //关闭弹窗

    gbtc() {

      // alert(11)

      this.dialogVisible = false


    },


    // 点击获取坐标


    openMapDialog() {

      this.dialogVisible = true;




      // this.inputValue_zuobiao1=


    },


    // 获取地图坐标参数到变量

    handleMapData(data) {

      this.inputValue_zuobiao1 = data.split("/")[0]
      this.inputValue_zuobiao2 = data.split("/")[1]




    },


    // 提交设置


    shangjiaxinxi() {
      // console.log('tijiao');


      // var formData = new FormData();

      // console.log(this.inputValue_mc);
      // console.log(this.inputValue_dh);
      console.log(this.inputValue_dizhi);
      console.log(this.Time1);

      const dateObject = new Date(this.Time1);
      const dateObject2 = new Date(this.Time2);


      const dateObject3 = new Date(this.Time3);
      const dateObject4 = new Date(this.Time4);




      const timestamp1 = dateObject.getTime(); // 时间1
      const timestamp2 = dateObject2.getTime(); // 时间2

      const timestamp3 = dateObject3.getTime(); // 时间1
      const timestamp4 = dateObject4.getTime(); // 时间2


      const hebingshijian = timestamp1 + '/' + timestamp2

      const hebingshijian2 = timestamp3 + '/' + timestamp4


      console.log(timestamp2);
      console.log(timestamp1);


      // console.log(this.inputValue_paixu);
      console.log(this.inputValue_zuobiao1);
      console.log(this.inputValue_zuobiao2);
      // console.log(this.value1 + "/" + this.value2);
      // console.log(this.imageFile); // 替换yourFile为实际的文件对象



      // console.log(this.Time2);
      // console.log(this.$route.query.id);


      let token = localStorage.getItem('token');


      var formData = new FormData();
      formData.append('file', this.imageFile); // 替换yourFile为实际的文件对象
      formData.append('mingcheng', this.inputValue_mc);
      formData.append('paixu', this.inputValue_paixu);
      formData.append('shouji', this.inputValue_dh);
      formData.append('fenlei', this.value1 + "/" + this.value2);
      // formData.append('logo', 0);
      formData.append('id', this.$route.query.id);
      formData.append('dizhi', this.inputValue_dizhi);
      formData.append('shijian', hebingshijian); // 营业时间1
      formData.append('shijian2', hebingshijian2); // 营业时间2

      formData.append('txname', this.txname);

      formData.append('txzhanghao', this.txzhanghao);

      formData.append('dianshu', this.dianshu);

      formData.append('qisongjia', this.qisongjia);






      formData.append('zuobiao', this.inputValue_zuobiao1 + '/' + this.inputValue_zuobiao2); // 坐标






      axios.post('https://api.dfwlkeji.com/shangjia/xiugaishangjia_ziliao', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        }
      })
        .then(response => {

          // 请求成功后的处理逻辑

          console.log('请求成功');


          this.$message.success('保存成功');

          // 刷新当前页面
          location.reload();


        })

        .catch(error => {
          // 请求失败后的处理逻辑
        });







      // console.log();






    },





    // 上传logo

    handleFileChange(event) {



      this.imageFile = event.target.files[0];
      const file = event.target.files[0];

      console.log(this.imageFile);


      const reader = new FileReader();

      reader.onload = (e) => {
        this.previewURL = e.target.result;
      };

      reader.readAsDataURL(file);








    },







    // 点击获取小地图

    dianjiditu() {




      console.log('diur');




    }


















  }

  // shangjiaxinxi














};
</script>

<style>
.custom-jichu {
  width: 10px;
  min-width: 100px;
  height: 300px;
}

.xuanzeqishangjiayemian {
  margin-top: 20px;
  /* 设置上方间距，根据实际情况调整 */
}



.zuobiaoshurukuang {
  display: flex;
  align-items: center;
}

.zuobiaoshurukuang h5 {
  margin-right: 10px;
}

.zuobiaoshurukuang el-input {
  margin-right: 10px;
}



.input-dianpumingcheng {
  display: flex;
  align-items: left;
}

.input-dianpumingcheng h5 {
  margin-right: 300px;
}

.zuobiaoshurukuang h5 {
  margin-right: 300px;

}

/* 时间选择器样式 */
.time-picker-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
}



/* 商家LOGO */

.avatar-rjstx {
  display: flex;
  align-items: center;
}

.text {
  margin-right: 10px;
}

.avatar-sjtxaa img {
  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  border-radius: 0;
}

.logoxuanzeanniu {
  transform: translate(500px, -50px);


}

/* 提交保存的按钮 */
.logoxuanzeanniu2 {
  transform: translate(800px, -0px);


}


/* 营业资质样式 */
.avatar-zhizhao {

  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  border-radius: 0;


}

.avatar-weishengxukezheng {

  width: 100px;
  height: 100px;
  /* border-radius: 50%; */
  border-radius: 0;


}

/* 营业资质提交保存 */
.logoxuanzeanniu3 {
  transform: translate(800px, -5px);


}
</style>