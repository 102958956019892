<template>
    <div>
        <el-form>

            <el-form-item label="平台状态">


                <el-switch :value="businessStatus" active-text="营业" inactive-text="休息" @change="gg_zhuangtai()"></el-switch>
            </el-form-item>



            <el-form-item label="打烊提示语">
                <el-input v-model="inputValue" :maxlength="100" size="medium" :style="{ width: '400px' }"></el-input>
            </el-form-item>



            <div class="input-group">
                雨天服务
                <el-switch :value="feiyong_zhuangtai1" active-text="" inactive-text=""></el-switch>

                <el-input v-model="input1" size="mini" placeholder="雨天服务" style="width: 100px;"></el-input>元

            </div>





            <div class="input-group" style="float: left; width: 50%;">

                包装费

        
                <el-switch :value="feiyong_zhuangtai2" active-text="" inactive-text=""></el-switch>

                <el-input v-model="input2" size="mini" placeholder="包装费" style="width: 100px;"></el-input>元

            </div>





            <div class="input-group">
                节日配送费
                <el-switch :value="feiyong_zhuangtai3" active-text="" inactive-text=""></el-switch>

                <el-input v-model="input3" size="mini" placeholder="节日配送费" style="width: 100px;"></el-input>元
            </div>

            <div class="input-group">
                平台服务费
                <el-switch :value="feiyong_zhuangtai4" active-text="" inactive-text=""></el-switch>

               

                <el-input v-model="input4" size="mini" placeholder="平台服务费" style="width: 100px;"></el-input>元
            </div>


            <el-button type="primary" @click="submitForm">提交</el-button>


        </el-form>
    </div>
</template>
  
<script>

import axios from "axios";

export default {
    data() {
        return {
            businessStatus: false, // 营业状态的值
            inputValue: '', // 输入框的值

            input1: '', // 输入框1的值
            input2: '', // 输入框2的值
            input3: '',// 输入框3的值
            input4: '',// 输入框3的值

            feiyong_zhuangtai1: false,
            feiyong_zhuangtai2: false,
            feiyong_zhuangtai3: false,
            feiyong_zhuangtai4: false,
        };

        


    },

    created() {

        // https://api.dfwlkeji.com/api/ptzt_chaxun

        let token = localStorage.getItem('token');

        // console.log(token);

        let headers = {

            "Authorization": token
        };

        axios.get('https://api.dfwlkeji.com/api/ptzt_chaxun').then((res) => {

            console.log(res.data.data[0].ZT_tishi);

            this.inputValue = res.data.data[0].ZT_tishi
            if (res.data.data[0].ZT_zhuangtai == 1) {
                this.businessStatus = true

            }


        })

        // ptzt_feiyong


        axios.get('https://api.dfwlkeji.com/api/ptzt_feiyong').then((res) => {

            console.log(res.data.data);
            var shuju = res.data.data

            console.log(shuju);

            if (shuju.length == 4) {

                this.input1 = shuju[0].FY_jiage;
                this.input2 = shuju[1].FY_jiage;
                this.input3 = shuju[2].FY_jiage;
                this.input4 = shuju[3].FY_jiage;

                if (shuju[0].FY_zhuangtai == 1) {

                    this.feiyong_zhuangtai1 = true


                }
                if (shuju[1].FY_zhuangtai == 1) {

                    this.feiyong_zhuangtai2 = true


                }
                if (shuju[2].FY_zhuangtai == 1) {

                    this.feiyong_zhuangtai3 = true


                }

                if (shuju[3].FY_zhuangtai == 1) {

                    this.feiyong_zhuangtai4 = true


                }


            }

            // FY_jiage

            // this.inputValue = res.data.data[0].ZT_tishi
            // if (res.data.data[0].ZT_zhuangtai == 1) {
            //     this.businessStatus = true

            // }


        })


    },


    methods: {

        gg_zhuangtai() {
            // 处理提交表单的逻辑

            let token = localStorage.getItem('token');

            // console.log(token);

            let headers = {

                "Authorization": token
            };

            if (this.businessStatus == false) {
                this.businessStatus = true


            } else {

                if (this.businessStatus == true) {
                    this.businessStatus = false

                }


            }


            console.log(this.businessStatus);

            if (this.businessStatus) {

                var zt = 1

            } else {

                var zt = 0
            }

            axios.get('https://api.dfwlkeji.com/my/ptzt', {
                params: {
                    id: zt,

                    tishiyu: this.inputValue
                },
                headers: headers
            }).then((res) => {
                console.log(res.data.message == '修改成功');
                if (res.data) {

                    // alert("已设置")

                } else {
                    alert('设置失败')

                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });





        },

        submitForm() {


        }

    }
};
</script>
  
<style>

.input-group{

    margin-top: 10px;
    margin-left: 5px;
    padding-bottom: 10px;


}


</style>