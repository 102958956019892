<template>
  <P>数据的页面</P>
</template>

<script>
export default {

  created() {

    const id = this.$route.query.id; // 获取id参数的值
    console.log(id);
    console.log('iii');

    










  }

}
</script>

<style>

</style>